import {
  API_ENDPOINT_DOCUMENT,
  DEFAULTRESPONSE,
  defaultHeaders,
  API_ENDPOINT_SIGNALR,
  TIME_UPDATE_REGISTER,
} from '../config/config';
import {
  handleResponse,
  enterpriseIdHeader,
  idUser,
  fullNameUser,
  emailUser,
  roleUser,
  businessName,
  nitCompany,
} from './authActions';
import { axiosApiInstance } from '../config/axios-instance';
import { SuccessAlert } from '../helpers/alert.helpers';
import {
  errorToast,
  successToast,
  warningToast,
} from '../helpers/toast.helpers';
import { formatDate } from '../utils/general';
import { types } from '../types/types';

const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/Documento/`;
const urlQuotation = `${API_ENDPOINT_DOCUMENT}/document/api/Aceptacion/`;
const urlReport = `${API_ENDPOINT_DOCUMENT}/document/api/report/`;
const urlBulkLoadClient = `${API_ENDPOINT_DOCUMENT}/document/api/customer/`;
const urlBulkLoad = `${API_ENDPOINT_DOCUMENT}/document/api/masivo/`;
const urlSignalR = `${API_ENDPOINT_SIGNALR}/signal/api/BulkLoad/`;

var loadingReportDocument = [];

/**
 * Consulta listado de documentos por parametros de busqueda
 * @param {object} filterData Datos filtro
 */
export const getDocumentsAction = (
  filterData,
  invoices,
  selectDefaultItem
) => async (dispatch) => {
  try {
    //Limpia listado en pantlla para nueva consulta
    if (filterData.LoadMore !== true) {
      dispatch({
        type: types.GET_INVOICES,
        payload: [],
        payload1: 0,
      });
    }

    changeGetLoading(dispatch, true);
    const response = await axiosApiInstance.post(
      `${urlInvoice}GetDocumentsPaginationSearch`,
      filterData,
      defaultHeaders()
    );

    let newInvoices = [];
    let total = 0;

    if (filterData.LoadMore === true) {
      newInvoices = invoices.concat(response.data.result);
      total = response?.data?.result[0]?.totalregistros;
    } else {
      newInvoices = response.data.result;
      total = response?.data?.result[0]?.totalregistros;
      selectDefaultItem(newInvoices[0]?.id);
    }

    dispatch({
      type: types.GET_INVOICES,
      payload: newInvoices,
      payload1: total,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado de documentos.',
        error: err,
      },
    });
  } finally {
    changeGetLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta listado documentos
 * @param {*} dispatch
 * @param {*} status
 */
function changeGetLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_DOCUMENTS,
    payload: status,
  });
}

export const getAditionalData = (id, userid) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: id,
      pUserId: userid,
    });

    const resp = await axiosApiInstance.get(
      `${urlInvoice}GetAdicionalDataEnterprise`,
      config
    );

    const { data } = resp;
    data.adsucursalesempresa = !!data.adsucursalesempresa
      ? data.adsucursalesempresa.map((item) => ({
          ...item,
          value: item.id,
          text: `${item.nombre} ${item.direccion}`,
        }))
      : [];

    data.advendedor = !!data.advendedor
      ? data.advendedor.map((item) => ({
          ...item,
          value: item.id,
          text: `${item.nombres} ${item.apellidos}`,
        }))
      : [];

    data.adnumeracion = !!data.adnumeracion
      ? data.adnumeracion.map((item) => ({
          ...item,
          value: item.id,
          text: `${item.descripcion} - Prefijo: ${item.prefijo} - ${
            item.nombre
          } - ${formatDate(item.fechafinal, 'dd/MM/yyyy')}`,
        }))
      : [];

    dispatch({
      type: types.GET_ADITIONAL_DATA_INVOICE,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener información adicional de la empresa.',
        error: err,
      },
    });
  }
};

/**
 * Consulta información de un documento
 * @param {*} id Identificador documento
 * @param {function} functionLoad Actualiza estado Loading, durante consulta de informaci´´on
 */
export const getDocumentDetailAction = (id) => async (dispatch) => {
  try {
    chaneGetDetailLoading(dispatch, true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      idDocumento: id,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentoById`,
      config
    );

    dispatch({
      type: types.GET_DOCUMENT,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener información de documento.',
        error: err,
      },
    });
  } finally {
    chaneGetDetailLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta listado documentos
 * @param {*} dispatch
 * @param {*} status
 */
function chaneGetDetailLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_DETAIL_DOCUMENT,
    payload: status,
  });
}

/**
 * Consulta información documento Nota credito, debito
 * @param {*} id Identificador nota
 * @param {*} getresponse Funcion leer response
 * @returns
 */
export const getNoteDetailAction = (id, getresponse) => async (dispatch) => {
  try {
    changeGetNoteLoading(dispatch, id);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      idDocumento: id,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentoById`,
      config
    );
    getresponse(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener información de nota.',
        error: err,
      },
    });
  } finally {
    changeGetNoteLoading(dispatch, 0);
  }
};

/**
 * Actualiza estado loading detalle nota (credito, debito)
 * @param {*} dispatch
 * @param {*} status
 */
function changeGetNoteLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_NOTE_DOCUMENT,
    payload: status,
  });
}

/**
 * Actualiza estado de documento por pagar a pagado y  documento destacado
 * @param {*} data Informacion documento
 */
export const updateDocumentAction = (
  data,
  invoices,
  selectDefaultItem
) => async (dispatch) => {
  try {
    changeUpdateStatusLoading(dispatch, true, data.Operacion);
    const response = await axiosApiInstance.post(
      `${urlInvoice}UpdateDocument`,
      data,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      const config = defaultHeaders();
      let newinvoices = [];
      let total = 0;
      let respDocument = null;

      //Para Borrado de documento
      if (data.Operacion === 'BORRADO') {
        //Actauliza nuevo total de documentos
        newinvoices = invoices.map((item) => {
          return {
            ...item,
            totalregistros: item.totalregistros - 1,
          };
        });

        //Quita documento borrado
        newinvoices = newinvoices.filter((d) => d.id !== data.Id);
        total = newinvoices[0]?.totalregistros ?? 0;

        //Selecciona documento en listado
        let newid = newinvoices[0]?.id ?? 0;
        selectDefaultItem(newid);
      } else {
        Object.assign(config.headers, {
          idDocumento: data.Id,
        });

        respDocument = await axiosApiInstance.get(
          `${urlInvoice}GetDocumentoById`,
          config
        );

        //Actualiza listado documentos
        newinvoices = updateDocumentsAfterUpdate(
          invoices,
          respDocument?.data?.result
        );
        total = newinvoices[0]?.totalregistros ?? 0;

        //Carga en redux detalle documento

        dispatch({
          type: types.GET_DOCUMENT,
          payload: respDocument.data.result,
        });
      }

      //Carga en redux listado documentos
      dispatch({
        type: types.GET_INVOICES,
        payload: newinvoices,
        payload1: total,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error actualizando documento.',
        error: err,
      },
    });
  } finally {
    changeUpdateStatusLoading(dispatch, false, data.Operacion);
  }
};

/**
 * Actualiza estado de documento por pagar a pagado y  documento destacado
 * @param {*} data Informacion documento
 */
export const updateDocumentFromListAction = (
  data,
  invoices,
  selectedId
) => async (dispatch) => {
  try {
    changeUpdateFromListLoading(dispatch, data.Id);
    const response = await axiosApiInstance.post(
      `${urlInvoice}UpdateDocument`,
      data,
      defaultHeaders()
    );

    //Actualiza detalle documento
    if (response.status === 200) {
      const config = defaultHeaders();
      Object.assign(config.headers, {
        idDocumento: data.Id,
      });

      const resp = await axiosApiInstance.get(
        `${urlInvoice}GetDocumentoById`,
        config
      );

      //Actualiza listado documentos
      let newinvoices = updateDocumentsAfterUpdate(
        invoices,
        resp?.data?.result
      );
      let total = newinvoices[0]?.totalregistros ?? 0;

      dispatch({
        type: types.GET_INVOICES,
        payload: newinvoices,
        payload1: total,
      });

      if (selectedId === data.Id) {
        dispatch({
          type: types.GET_DOCUMENT,
          payload: resp.data.result,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error actualizando documento.',
        error: err,
      },
    });
  } finally {
    changeUpdateFromListLoading(dispatch, 0);
  }
};

/**
 * Actualiza estado loading actualizacion estado documento desde lista
 * @param {*} dispatch
 * @param {*} status
 * @param {*} id
 */
function changeUpdateFromListLoading(dispatch, id) {
  dispatch({
    type: types.LOADING_UPDATE_FROM_LIST,
    payload: id,
  });
}

/**
 * Actualiza estado loading actualizacion estado documento
 * @param {*} dispatch
 * @param {*} status
 */
function changeUpdateStatusLoading(dispatch, status, operacion) {
  if (operacion === 'PAGADO') {
    dispatch({
      type: types.LOADING_UPDATE_STATUS_DOCUMENT,
      payload: status,
    });
  }

  if (operacion === 'FAVORITO') {
    dispatch({
      type: types.LOADING_UPDATE_FAVOURITE_DOCUMENT,
      payload: status,
    });
  }

  if (operacion === 'BORRADO') {
    dispatch({
      type: types.LOADING_DELETE_DOCUMENT,
      payload: status,
    });
  }
}

/**
 * Actualiza listado de documentos despues  de actualizar edtado
 * @param {*} invoices Listado documentos
 * @param {*} data nueva informacion documento
 */
const updateDocumentsAfterUpdate = (invoices, data) => {
  if (data !== null && data !== undefined) {
    let newData = invoices.find((d) => d.id === data?.id);
    newData = {
      ...newData,
      estado: data.estado,
      favorito: data.favorito,
      estadoid: data.estadoid,
    };

    const index = invoices.findIndex((d) => d.id === data?.id);
    let newInvoices = [];
    if (index >= 0) {
      newInvoices = [...invoices];
      newInvoices[index] = newData;
    }
    newInvoices = newInvoices.filter((c) => c.idstate !== 2);
    return newInvoices;
  }
};

export const updateDocument = (data, funtionResponse) => async (dispatch) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}UpdateDocument`,
      data,
      defaultHeaders()
    );
    if (response.status === 200) {
      funtionResponse();
      dispatch({
        type: types.SUCESS_DOCUMENT_UPDATE,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error actualizando documento.',
        error: err,
      },
    });
  }
};

/**
 * Registra información de nueva factura
 * @param {*} data Información factura
 * @param {*} funtionResponse
 * @param {*} functionError
 */
export const createElectronicInvoice = (
  data,
  funtionResponse,
  functionError
) => async (dispatch) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}CreateDocumentInvoice`,
      data,
      defaultHeaders()
    );

    if (
      response.data.statusCode === '201' ||
      response.data.statusCode === '200'
    ) {
      funtionResponse(response);
      dispatch({
        type: types.SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (error) {
    functionError(error.response);
    if (
      error?.hasOwnProperty('response') &&
      error?.response?.hasOwnProperty('data') &&
      error?.response?.data?.statusCode === '404' &&
      error?.response?.data?.statusMessage === 'Unauthorized, no quota'
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: error.response.data,
      });

      if (
        error.response.data.result.licenseActiveBase &&
        !error.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    } else {
      handleResponse(error.response);
    }
  }
};

/**
 * Abre el modal que informa que no hay cupo para crear facturas
 * @param {object} data boolean en true
 */
export const updateOpenModalItem = (data) => (dispatch) => {
  dispatch({
    type: types.INFO_DOCUMENT_CREATE,
    payload: data,
  });
};

/**
 * limpia estado redux consulta licencia base
 * */
export const cleanLicenseBaseAction = () => (dispatch) => {
  dispatch({
    type: types.INFO_LICENSE_BASE,
    payload: null,
  });
};

export const resendElectronicInvoice = (data, funtionResponse) => async (
  dispatch
) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}ReenviarFacturaElectronicaId`,
      data,
      defaultHeaders()
    );
    if (response.status === 200) {
      funtionResponse();
      dispatch({
        type: types.RESEND_ELECTRONIC_INVOICE,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error reenviando documento.',
        error: err,
      },
    });
  }
};

export const createQoutation = (data, funtionResponse, functionError) => async (
  dispatch
) => {
  try {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      url: `${urlQuotation}typeAcceptance`,
    });

    const response = await axiosApiInstance.post(
      `${urlInvoice}createDocumentQuotation`,
      data,
      config
    );
    if (response.data.statusCode === '201') {
      funtionResponse(response);
      dispatch({
        type: types.SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (err) {
    functionError(err.response);
  }
};

/**
 * Edita información de cotización
 * @param {*} data
 * @param {*} funtionResponse
 * @param {*} functionError
 */
export const editQoutation = (data, funtionResponse, functionError) => async (
  dispatch
) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}editDocumentQuotation`,
      data,
      defaultHeaders()
    );
    if (response.data.statusCode === '201') {
      funtionResponse(response);
      dispatch({
        type: types.SUCESS_DOCUMENT_CREATE,
        payload: response.data,
      });
    }
  } catch (err) {
    functionError(err.response);
  }
};

/**
 * Envia la factura o nota a un correo
 * @param {*} body Informacion de la factura a enviar
 */
export const sendDocumentAction = (body, type) => async (dispatch) => {
  try {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pUrl: body.TipoModalidad === 0 ? `${urlQuotation}typeAcceptance` : '',
    });

    const response = await axiosApiInstance.post(
      `${urlInvoice}SendDocument`,
      body,
      config
    );
    if (response.status === 200) {
      if (type === 'FA') {
        SuccessAlert(
          null,
          'Se realizó el reenvío de la Factura exitosamente',
          TIME_UPDATE_REGISTER
        );
      } else if (type === 'CO') {
        SuccessAlert(
          null,
          'Se realizó el reenvío de la Cotización exitosamente',
          TIME_UPDATE_REGISTER
        );
      } else if (type === 'EQUIVALENT') {
        SuccessAlert(
          null,
          'Se realizó el reenvío del Documento Equivalente exitosamente',
          TIME_UPDATE_REGISTER
        );
      } else {
        SuccessAlert(
          null,
          'Se realizó el reenvío de la Nota exitosamente',
          TIME_UPDATE_REGISTER
        );
      }
    }

    dispatch({
      type: types.SUCESS_SEND_INVOICE,
      payload: response.data,
    });
  } catch (err) {
    console.log(
      'Error en el envio de un documento',
      !!err?.response ? err.response : err
    );
    let response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    readResponseEmailService(response);
    dispatch({
      type: types.SUCESS_SEND_INVOICE,
      payload: response,
    });
  }
};

/**
 *  Lee repuesta servico email, reenvio documentos
 * @param {*} response
 */
const readResponseEmailService = (response) => {
  let message = response.statusMessage ?? '';
  switch (response.httpCode) {
    case 500:
      errorToast('Error 500', message);
      break;
    case 400:
      warningToast('Error 400, petición invalida', message);
      break;
    case 404:
      warningToast('Error 404 usuario no autorizado', message);
      break;
    case 401:
      errorToast('Error 401 error no controlado"', message);
      break;
    default:
      errorToast(
        'Upss!!"',
        'Ha ocurrido un error no identificado, No se ha podido enviar correo electrónico, Por favor contacte al administrador'
      );
      break;
  }
};

/**
 * @function
 * @description Asigna estado para mostrar o ocultar
 * catalogo de articulos desde la generación de facturas
 * @param {Estado} status
 */
export const setStatusShowCatalogAction = (status) => (dispatch) => {
  dispatch({
    type: types.SHOW_CATALOG_ITEMS,
    payload: status,
  });
};

export const getTotalDocs = (id, tipoelec) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pIdEmpresa: id,
      pTipoDocElec: tipoelec,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetTotalDocuments`,
      config
    );
    dispatch({
      type: types.GET_TOTAL_DOCUMENTS,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error Obteniendo total documentos.',
        error: err,
      },
    });
  }
};

export const getQuotation = (id, functionLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pIdEmpresa: id,
      idDocumento: id,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentoById`,
      config
    );
    if (response.data.statusCode === '200') {
      functionLoad();
    }
    dispatch({
      type: types.GET_QUOTATION,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error Obteniendo cotización.',
        error: err,
      },
    });
  }
};

/**
 * Consulta listado motivos generación notas
 * @returns
 */
export const getReasonNotes = () => async (dispatch, getState) => {
  try {
    const { reasons } = getState().invoiceReducer;
    if (reasons.length > 0) return;

    dispatch(statusLoadingReasons(true));
    const response = await axiosApiInstance.get(
      `${urlInvoice}GetReasonNote`,
      defaultHeaders()
    );

    if (response.status === 200) {
      dispatch({
        type: types.GET_REASONS_NOTES,
        payload: response.data.result,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error obteniendo listado conceptos notas.',
        error: err,
      },
    });
  } finally {
    dispatch(statusLoadingReasons(false));
  }
};

const statusLoadingReasons = (status) => {
  return {
    type: types.LOADING_REASONS_NOTES,
    payload: status,
  };
};

/**
 * Registra información de un nota credito o debito
 * @param {*} data Información documento
 * @param {*} funtionResponse
 * @param {*} functionError
 */
export const createNote = (data, funtionResponse, functionError) => async (
  dispatch
) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}createDocumentNote`,
      data,
      defaultHeaders()
    );

    funtionResponse(response);
    dispatch({
      type: types.SUCESS_DOCUMENT_CREATE,
      payload: response.data,
    });
  } catch (err) {
    console.log('Error registrando Nota', !!err?.response ? err.response : err);
    functionError(err.response);
    if (
      err?.hasOwnProperty('response') &&
      err?.response?.hasOwnProperty('data') &&
      err?.response?.data?.statusCode === '404' &&
      err?.response?.data?.statusMessage === 'Unauthorized, no quota'
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: err.response.data,
      });

      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    } else {
      handleResponse(err.response);
    }
  }
};

export const createNoteAnnulmentAction = (
  data,
  funtionResponse,
  functionError,
  dataAnnulment
) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      idDocumento: data?.opdocumento?.documentoreferenciaid,
    });

    const response = await axiosApiInstance.post(
      `${urlInvoice}createDocumentNote`,
      data,
      defaultHeaders()
    );
    const respdetail = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentoById`,
      config
    );

    //Actualiza listado y detalle documentos
    if (dataAnnulment !== null) {
      const index = dataAnnulment.invoices.findIndex(
        (d) => d.id === data?.opdocumento?.documentoreferenciaid
      );
      dataAnnulment.invoices[index].anulacion = true;
      let total = dataAnnulment.invoices[0]?.totalregistros ?? 0;
      dispatch({
        type: types.GET_INVOICES,
        payload: dataAnnulment.invoices,
        payload1: total,
      });

      dispatch({
        type: types.GET_DOCUMENT,
        payload: respdetail.data.result,
      });
    }

    funtionResponse(response);
  } catch (err) {
    console.log(
      'Error registrando Anulacion',
      !!err?.response ? err.response : err
    );
    functionError(err.response);
    if (
      err?.hasOwnProperty('response') &&
      err?.response?.hasOwnProperty('data') &&
      err?.response?.data?.statusCode === '404' &&
      err?.response?.data?.statusMessage === 'Unauthorized, no quota'
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: err.response.data,
      });

      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
    } else {
      handleResponse(err.response);
    }
  }
};

/**
 * Obtener xml y pdf de un documento
 * @param {*} data
 * @param {*} functionLoad
 */
export const getDocumentFiles = (data, functionLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: data.TipoDocumento,
      pDocumento: data.Documento,
      pCodigoUnico: data.CodigoUnico,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentFiles`,
      config
    );
    if (response.data.statusCode === '200') {
      functionLoad(response.data.result);
    }
    dispatch({
      type: types.GET_FILE_INVOICE,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error obteniendo archivos.',
        error: err,
      },
    });
  }
};

/**
 * Consula PDF en base64 de documento electronico
 * @param {*} data Información documento
 * @returns
 */
export const getDocumentFilesAction = (data, readResponseGetFiles) => async (
  dispatch
) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: data.tipodocumento,
      pDocumento: data.documento,
      pCodigoUnico: data.codigounico,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetDocumentFiles`,
      config
    );
    readResponseGetFiles(response?.data?.result ?? null);
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message: 'Ha ocurrido un error, No hemos podido obtener PDF.',
        error: err,
      },
    });
  }
};

/**
 * Genera previsualización de documento en construccion
 * @param {*} data
 * @param {*} readResponseGetFiles
 * @returns
 */
export const getDocumentPreviewAction = (data, readResponseGetFiles) => async (
  dispatch
) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}GetPreviewFile`,
      data,
      defaultHeaders()
    );

    readResponseGetFiles(response?.data?.result ?? null);
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message:
          'Ha ocurrido un error, No hemos podido generar previsualización.',
        error: err,
      },
    });
  }
};

export const getFilePreview = (data, funtionResponse, closeModal) => async (
  dispatch
) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlInvoice}GetPreviewFile`,
      data,
      defaultHeaders()
    );
    if (response.data.statusCode === '201') {
      funtionResponse(response.data.result);
      dispatch({
        type: types.GET_FILE_INVOICE,
        payload: response.data,
      });
    } else {
      closeModal();
    }
  } catch (err) {
    closeModal();
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido generar previsualización.',
        error: err,
      },
    });
  }
};

export const getReportApi = (pempresa, pano, pmes) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresa: `${enterpriseIdHeader()}`,
      pAno: `${pano}`,
      pMes: pmes,
    });

    const response = await axiosApiInstance.get(
      `${urlReport}GetReport`,
      config
    );
    dispatch({
      type: types.GET_INFORME,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error obteniendo reporte.',
        error: err,
      },
    });
  }
};

/**
 * Consulta información  para generar reporte contadores con rango de fechas
 * @param {string} pFechaDesde Fecha de inicio
 * @param {string} pFechaHasta Fecha de fin
 * @param {function} completar Funcion, completa  descarga despues de obtener información de api
 */
export const getReportAction = (
  pFechaDesde,
  pFechaHasta,
  complete,
  changeLoadingStatus
) => async (dispatch) => {
  try {
    changeLoadingStatus(true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: `${enterpriseIdHeader()}`,
      pFechaDesde: pFechaDesde,
      pFechaHasta: pFechaHasta,
    });

    const response = await axiosApiInstance.get(
      `${urlReport}GetReport`,
      config
    );

    dispatch({
      type: types.GET_INFORME,
      payload: response.data.result,
    });

    complete(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información de reporte.',
        error: err,
      },
    });
  } finally {
    changeLoadingStatus(false);
  }
};

export const setActiveStepInvoiceAction = (step) => async (dispatch) => {
  dispatch({
    type: types.ACTIVE_STEP_INVOICE,
    payload: step,
  });
};

/**
 * Actualiza estado en redux, ver seccion generación nueva factura
 * @param {boolean} status Nuevo estado
 */
export const changeShowInvoiceCreationAction = (status) => async (dispatch) => {
  dispatch({
    type: types.SHOW_INVOICE_CREATION,
    payload: status,
  });
};

/**
 * Obtiene documento PDF en base 64 para documentos con modalidad no electronica
 * @param {int} documentId Identificador documento
 */
export const getNonElectronicDocumentFileAction = (
  documentId,
  funtionResponse,
  closeModal
) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pIdentificador: documentId,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetNonElectronicDocumentFile`,
      config
    );
    var data = {
      archivoBase64: response?.data?.result,
    };

    funtionResponse(data);
  } catch (err) {
    closeModal();
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener documento.',
        error: err,
      },
    });
  }
};

export const getNonElectronicFilesAction = (id, readResponseGetFiles) => async (
  dispatch
) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pIdentificador: id,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetNonElectronicDocumentFile`,
      config
    );
    var data = [
      {
        archivoBase64: response?.data?.result ?? null,
        tipoContenido: 'application/pdf',
      },
    ];
    readResponseGetFiles(data);
  } catch (err) {
    readResponseGetFiles(null);
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener documento.',
        error: err,
      },
    });
  }
};

/**
 * Consulta listado de tarifas para Rete-Fuente
 */
export const getListReteFuenteAction = () => async (dispatch, getState) => {
  try {
    const { listReteFuente } = getState().invoiceReducer;
    if (listReteFuente.length > 0) return;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      ptiporetencionid: 1,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetWitholdingTax`,
      config
    );
    dispatch({
      type: types.GET_LIST_RETEFUENTE,
      payload: response.data.result.filter((c) => c.id !== 0),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado tarifas Rete-Fuente.',
        error: err,
      },
    });
  }
};

/**
 * Consulta listado de tarifas para Rete-Renta
 */
export const getListReteRentaAction = () => async (dispatch, getState) => {
  try {
    const { listReteRenta } = getState().invoiceReducer;
    if (listReteRenta.length > 0) return;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      ptiporetencionid: 4,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetWitholdingTax`,
      config
    );
    dispatch({
      type: types.GET_LIST_RETERENTA,
      payload: response.data.result.filter((c) => c.id !== 0),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado tarifas Rete-Fuente.',
        error: err,
      },
    });
  }
};

/**
 * Consulta listado de tarifas para Rete-ICA
 */
export const getListReteICAAction = () => async (dispatch, getState) => {
  try {
    const { listReteICA } = getState().invoiceReducer;
    if (listReteICA.length > 0) return;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      ptiporetencionid: 2,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetWitholdingTax`,
      config
    );

    dispatch({
      type: types.GET_LIST_RETEICA,
      payload: response.data.result.filter((c) => c.id !== 0),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado tarifas Rete-ICA.',
        error: err,
      },
    });
  }
};

/**
 * Consulta listado de tarifas para Rete-IVA
 */
export const getListReteIVAAction = () => async (dispatch, getState) => {
  try {
    const { listReteIVA } = getState().invoiceReducer;
    if (listReteIVA.length > 0) return;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      ptiporetencionid: 3,
    });

    const response = await axiosApiInstance.get(
      `${urlInvoice}GetWitholdingTax`,
      config
    );
    dispatch({
      type: types.GET_LIST_RETEIVA,
      payload: response.data.result.map((item) => ({
        ...item,
        value: item.id === 0 ? '' : item.id,
        text: item.descripcion,
      })),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado tarifas Rete-IVA.',
        error: err,
      },
    });
  }
};

/**
 * End point de validacion de informacion que se carga desde excel.
 * @param {Data a validar} inputList
 * @returns
 */
export const checkValidationsAction = (inputList) => async (dispatch) => {
  try {
    if (inputList != null) {
      const response = await axiosApiInstance.post(
        `${urlBulkLoad}ValidateInformation`,
        inputList,
        defaultHeaders()
      );

      if (!!response) {
        dispatch({
          type: types.GET_VALIDATION_BULK_LOAD,
          payload: response.data,
        });
      }
    }
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido validar los datos ingresados.',
        error: err,
      },
    });
  }
};

/**
 * Consumo de End point para almacenar la información de las facturas cargadas por medio de cargue masivo.
 * @param {*} inputList
 * @returns
 */
export const saveUploadedInvoiceAction = (inputList) => async (dispatch) => {
  try {
    if (inputList != null) {
      const response = await axiosApiInstance.post(
        `${urlBulkLoad}RegisterInformation`,
        inputList,
        defaultHeaders()
      );

      if (response.data.result != null) {
        dispatch({
          type: types.SAVE_REGISTER_BULK_LOAD,
          payload: response.data.statusCode,
        });
      }
    }
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message:
          'No se ha podido registrar las facturas cargadas desde cargue masivo.',
        error: err,
      },
    });
  }
};

/**
 * Consumo de End ponmit de consulta de información del ultimo registro cargado por el usuario..
 * @param {*} empresaId
 * @param {*} usuarioId
 */
export const getUploadedInvoiceAction = (stepAction) => async (dispatch) => {
  try {
    dispatch({
      type: types.SAVE_REGISTER_BULK_LOAD,
      payload: 0,
    });

    const config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
      usuarioid: `${idUser()}`,
      step: stepAction,
    });

    const response = await axiosApiInstance.get(
      `${urlBulkLoad}ConsultInformation`,
      config
    );

    if (response.data.result != null) {
      dispatch({
        type: types.GET_INFORMATION_BULK_LOAD,
        payload: response.data.result,
        payload1: response.data.statusCode,
      });

      dispatch({
        type: types.STATUS_GET_INFORMATION_BULK_LOAD,
        payload: response.data.statusCode,
      });

      dispatch({
        type: types.UPDATE_STATE_BULK,
        payload: response.data.result.estadoCargue,
      });

      // return response.data.result;
    }
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message:
          'No se ha podido obtener la información de las facturas cargadas desde la opción de cargue masivo.',
        error: err,
      },
    });
  }
};

/**
 * Consumo de End point para actualizar el estado del cargue masivo cargado.
 *
 * @param {*} empresaId
 * @param {*} usuarioId
 *
 * @param {*} estadoId
 * @param {*} cargueId
 * @param {*} detalleId
 */
export const updateStateAction = (
  estadoId,
  cargueId,
  detalleId,
  view,
  setloadingbutton,
  setOpenModal
) => async (dispatch) => {
  try {
    if (estadoId != null && cargueId != null) {
      const response = await axiosApiInstance.put(
        `${urlBulkLoad}UpdateStatus`,
        buildDataActualizarEstado(
          `${enterpriseIdHeader()}`,
          `${idUser()}`,
          estadoId,
          cargueId,
          detalleId
        ),
        defaultHeaders()
      );

      dispatch({
        type: types.UPDATE_STATE_BULK,
        payload: estadoId,
        payload1: response.data.result,
        payload2: response.data.statusCode,
        payload3: response.data,
      });

      if (estadoId === 2 && view === 1) {
        dispatch({
          type: types.GET_CHANGE_STATE,
          payload: response.data,
        });
      }
    }
  } catch (err) {
    handleResponse(err.response);
    if (
      err?.response?.data?.statusMessage === 'Unauthorized, no quota' &&
      err?.response?.data?.statusCode === '404'
    ) {
      dispatch({
        type: types.INFO_LICENSE_BASE,
        payload: err.response.data,
      });
      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: types.INFO_DOCUMENT_CREATE,
          payload: true,
        });
      }
      setloadingbutton(false);
      setOpenModal(false);
    }
    if (err?.response?.data?.statusMessage !== 'Unauthorized, no quota') {
      dispatch({
        type: types.showError,
        payload: {
          message: 'Error realizando el cambio de estado del cargue masivo.',
          error: err,
        },
      });
    }
  } finally {
    cleanGetStatus(dispatch);
  }
};

/**
 * Limpia el estado donde se almacena las validaciones realizadas de las facturas cargadas masivamente.
 * @returns
 */
export const cleanValidationAction = () => (dispatch) => {
  dispatch({
    type: types.GET_VALIDATION_BULK_LOAD,
    payload: null,
  });
};

export const UpdateDataBulkAction = (data) => async (dispatch) => {
  dispatch({
    type: types.GET_INFORMATION_BULK_LOAD,
    payload: data,
  });
};

export const cleanStateGetBulkLoadAction = () => async (dispatch) => {
  dispatch({
    type: types.GET_INFORMATION_BULK_LOAD,
    payload: null,
  });
};

export const cleanStatusAction = () => async (dispatch) => {
  dispatch({
    type: types.STATUS_GET_INFORMATION_BULK_LOAD,
    payload: null,
  });
};

function cleanGetStatus(dispatch) {
  dispatch({
    type: types.GET_CHANGE_STATE,
    payload: {},
  });
}

/**
 * Limpiar store de objeto de respuesta de actualización del estado del cargue masivo activo.
 * @param {} clean
 * @returns
 */
export const cleanStatusUploadAction = (clean) => async (dispatch) => {
  if (clean === true) {
    dispatch({
      type: types.UPDATE_STATE_BULK,
      payload: 0,
      payload2: {},
      payload3: 0,
    });
  } else {
    dispatch({
      type: types.UPDATE_STATE_BULK,
      payload2: {},
    });
  }
};
/**
 * Función donde se almacena la respuesta que llega desde signal R cuando se genera una factura.
 * @param {*} resultdata
 * @returns
 */
export const resultSignal = (resultdata) => async (dispatch) => {
  dispatch({
    type: types.GET_RESULT_SIGNAL,
    payload: resultdata,
  });
};

/**
 * Función para limpiar el state de la respuesta de signal R cuando se genera una factura.
 * @returns
 */
export const clearResultSignalAction = () => async (dispatch) => {
  dispatch({
    type: types.GET_RESULT_SIGNAL,
    payload: {},
  });
};

export const informationInvoiceAction = (data) => async (dispatch) => {
  dispatch({
    type: types.GET_INFORMATION_BULK_LOAD,
    payload: data,
  });
};

/**
 * Cambia el valor de la vista de cargue masivo, dependiendo del paso en el cual se encuentra.
 * @param {*} step
 * @returns
 */
export const changeStepAction = (step) => async (dispatch) => {
  dispatch({
    type: types.CHANGE_STEP_BULK,
    payload: step,
  });
};

/**
 *
 * @param {*} empresaId
 * @param {*} usuarioId
 * @param {*} estadoId
 * @param {*} cargueId
 * @param {*} detalleId
 */
const buildDataActualizarEstado = (
  empresaId,
  usuarioId,
  estadoId,
  cargueId,
  detalleId
) => {
  let req = {
    Empresaid: empresaId,
    Usuarioid: usuarioId,
    Estado: estadoId,
    Cargueid: cargueId,
  };
  if (detalleId == null) {
    return req;
  }
  Object.assign(req, {
    Detalleid: detalleId,
  });
  return req;
};

/**
 * Suscribirse usuario a  grupo de signalR
 */
export const joinGroupSignalAction = (
  empresaId,
  usuarioId,
  connectionId
) => async (dispatch) => {
  try {
    const response = await axiosApiInstance.post(
      `${urlSignalR}joinGroup/${empresaId}/${usuarioId}/${connectionId}`,
      defaultHeaders()
    );
    if (response.data.result != null) {
    }
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido suscribir el cliente.',
        error: err,
      },
    });
  }
};

export const showBulkLoadAction = () => async (dispatch) => {
  dispatch({
    type: types.SHOW_BULK,
    payload: false,
  });
};

/**
 * Agrega nuevo documento de refer
 * @param {*} newData
 * @param {*} listDocumentReference
 */
export const addDocumentReferenceAction = (listDocumentReference) => async (
  dispatch
) => {
  try {
    dispatch({
      type: types.GET_DOCUMENTS_REFERENCE,
      payload: listDocumentReference,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'Error agregando documento de referncia.',
        error: err,
      },
    });
  }
};

/**
 * Carga tipo de nota a generar
 * @param {*} type tipo nota (credito, debito, anulacion)
 * @returns
 */
export const getTypeNoteAction = (type) => async (dispatch) => {
  dispatch({
    type: types.SET_TYPE_NOTE,
    payload: type,
  });
};

/**
 * validar existencia de registros en BD
 */
export const checkValidationsClientAction = (inputList) => async (dispatch) => {
  try {
    inputList = {
      ...inputList,
      empresaId: `${enterpriseIdHeader()}`,
    };
    if (inputList != null) {
      const response = await axiosApiInstance.post(
        `${urlBulkLoadClient}ValidateInformationClient`,
        inputList,
        defaultHeaders()
      );

      if (response.data.result != null) {
        return response.data.result;
      }
    }
    return null;
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido validar los datos ingresados.',
        error: err,
      },
    });
  }
};

/**
 * validar existencia de registros en BD
 */
export const checkAditionalValidationsClientAction = (inputList) => async (
  dispatch
) => {
  try {
    inputList = {
      ...inputList,
      empresaId: `${enterpriseIdHeader()}`,
    };
    if (inputList != null) {
      const response = await axiosApiInstance.post(
        `${urlBulkLoadClient}ValidateInformationAditionalClient`,
        inputList,
        defaultHeaders()
      );
      if (response.data.result != null) {
        return response.data.result;
      }
    }
    return null;
  } catch (err) {
    handleResponse(err.response);
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido validar los datos ingresados.',
        error: err,
      },
    });
  }
};

const axios = require('axios');
/**
 * Envia correo electrónico  con reporte documento rechazado DIAN
 * @param {*} data Información usuario y documento
 * @returns
 */
export const sendMailReportDocumentAction = (data, invoices) => async (
  dispatch
) => {
  try {
    changeReportLoading(dispatch, data.documentId, true);
    var result = await axios.post(
      `${urlInvoice}${'SendEmailReportDocument'}`,
      data,
      defaultHeaders()
    );

    if (result.status === 200) {
      let total = invoices[0]?.totalregistros ?? 0;
      let index = invoices.findIndex((c) => c.id === data.documentId);
      if (index !== undefined) {
        //<> undefined
        invoices[index].reporterechazo = true;
        dispatch({
          type: types.GET_INVOICES,
          payload: invoices,
          payload1: total,
        });

        successToast(
          `Reporte exitoso factura numero: ${data.numero}`,
          'Has reportado el caso exitosamente, no te preocupes nosotros nos encargaremos.'
        );
      } else {
        warningToast(
          `No hemos podido actualizar estado reporte de la factura numero: ${data.numero}`
        );
      }
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          'No hemos podido enviar el correo electrónico con reporte, por favor contacta al administrador.',
        error: err,
      },
    });
  } finally {
    changeReportLoading(dispatch, data.documentId, false);
  }
};

/**
 * Actualiza estado loading reporte documento
 * @param {*} dispatch
 * @param {*} status
 */
function changeReportLoading(dispatch, id, status) {
  if (status === true) {
    loadingReportDocument.push(id);
  } else {
    loadingReportDocument = loadingReportDocument.filter((c) => c !== id);
  }
  dispatch({
    type: types.LOADING_REPORT_DOCUMENT,
    payload: loadingReportDocument,
  });
}

export const getDataEmailReportAction = () => (dispatch) => {
  let data = {
    userName: fullNameUser(),
    emailUser: emailUser(),
    roleUser: roleUser(),
    businessName: businessName(),
    nitCompany: nitCompany(),
  };

  dispatch({
    type: types.GET_DATA_EMAIL_REPORT,
    payload: data,
  });
};

export const cleanDocumentDetailAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_DOCUMENT_DETAIL,
  });
};

/**
 * Carga cadena para filtrado incial  de documentos
 * @param {*} filter cadena para filtro
 * @returns
 */
export const setDefaultFilterAction = (filter) => async (dispatch) => {
  dispatch({
    type: types.SET_DEFAULT_FILTER,
    payload: filter,
  });
};

/**
 * Consulta información  para generar reporte facturacion Siigo
 * @param {date} dateFrom
 * @param {date} dateTo
 * @param {string} filter
 * @param {function} Funcion, completa  descarga despues de obtener información de api
 * @param {*} changeLoadingStatus
 * @returns
 */
export const getInvoiceReportAction = (
  dateFrom,
  dateTo,
  filter,
  complete,
  changeLoadingStatus
) => async (dispatch) => {
  try {
    changeLoadingStatus(true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: `${enterpriseIdHeader()}`,
      pFechaDesde: dateFrom,
      pFechaHasta: dateTo,
      pFiltro: filter,
    });

    const response = await axiosApiInstance.get(
      `${urlReport}ConsultarReporteFacturacion`,
      config
    );

    complete(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información de reporte.',
        error: err,
      },
    });
  } finally {
    changeLoadingStatus(false);
  }
};

/**
 * Consulta información  para generar reporte terceros Siigo
 * @param {date} dateFrom
 * @param {date} dateTo
 * @param {string} filter
 * @param {function} Funcion, completa  descarga despues de obtener información de api
 * @param {*} changeLoadingStatus
 * @returns
 */
export const getClientReportAction = (
  dateFrom,
  dateTo,
  filter,
  complete,
  changeLoadingStatus
) => async (dispatch) => {
  try {
    changeLoadingStatus(true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresa: `${enterpriseIdHeader()}`,
      pFechaDesde: dateFrom,
      pFechaHasta: dateTo,
      pFiltro: filter,
    });

    const response = await axiosApiInstance.get(
      `${urlReport}GetReportClient`,
      config
    );

    complete(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información de reporte.',
        error: err,
      },
    });
  } finally {
    changeLoadingStatus(false);
  }
};

/**
 * Consulta listado de cotizaciones por parametros de busqueda
 * @param {object} filterData Datos filtro
 */
export const getQuotationsAction = (
  filterData,
  invoices,
  selectDefaultItem
) => async (dispatch) => {
  try {
    //Limpia listado en pantlla para nueva consulta
    if (filterData.LoadMore !== true) {
      dispatch({
        type: types.GET_INVOICES,
        payload: [],
        payload1: 0,
      });
    }

    changeGetLoading(dispatch, true);
    const response = await axiosApiInstance.post(
      `${urlInvoice}ConsultarListadoCotizaciones`,
      filterData,
      defaultHeaders()
    );

    let newInvoices = [];
    let total = 0;

    if (filterData.LoadMore === true) {
      newInvoices = invoices.concat(response.data.result);
      total = response?.data?.result[0]?.totalregistros;
    } else {
      newInvoices = response.data.result;
      total = response?.data?.result[0]?.totalregistros;
      selectDefaultItem(newInvoices[0]?.id);
    }

    dispatch({
      type: types.GET_INVOICES,
      payload: newInvoices,
      payload1: total,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado de documentos.',
        error: err,
      },
    });
  } finally {
    changeGetLoading(dispatch, false);
  }
};
