import React from "react";

import { useSelector, useDispatch } from "react-redux";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ClearIcon from "@material-ui/icons/Clear";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
// import VisibilityIcon from "@material-ui/icons/Visibility";

import {
  clearEquivalentDocumentReducerAction,
  nextStepCreateDocumentAction,
  setEquivalentDocDataAction,
} from "../../../actions/equivalentDocAction";

export const ButtonIconRenderData = () => {
  const dispatch = useDispatch();

  const { equivalentDocumentForm } = useSelector(
    (state) => state.equivalentDocReducer
  );

  /**
   * * Lista de objetos que representan botones con iconos y textos asociados.
   * @property {Function} onClick - La función que se ejecuta al hacer clic en el botón.
   * @property {ReactNode} icon - El icono React que se muestra en el botón.
   * @property {string} text - El texto asociado al botón.
   * @property {boolean} [isLoading] - Un indicador opcional que muestra si el botón está en estado de carga.
   * @property {number} [xs] - El tamaño del botón en dispositivos extra pequeños.
   */
  const renderIconStepOne = [
    {
      onClick: () => {
        dispatch(
          setEquivalentDocDataAction({
            ...equivalentDocumentForm,
            favorite: !equivalentDocumentForm.favorite,
          })
        );
      },
      icon: equivalentDocumentForm.favorite ? <StarIcon /> : <StarBorderIcon />,
      text: "Destacar",
      xs: 6,
    },
    {
      onClick: () => {
        dispatch(clearEquivalentDocumentReducerAction());
      },
      icon: <ClearIcon />,
      text: "Cancelar",
      xs: 6,
    },
  ];

  const renderIconStepTwo = [
    {
      onClick: () => {
        dispatch(nextStepCreateDocumentAction(0));
      },
      icon: <ArrowBackIosIcon />,
      text: "Volver",
      xs: 4,
    },
    {
      onClick: () => {
        dispatch(
          setEquivalentDocDataAction({
            ...equivalentDocumentForm,
            favorite: !equivalentDocumentForm.favorite,
          })
        );
      },
      icon: equivalentDocumentForm.favorite ? <StarIcon /> : <StarBorderIcon />,
      text: "Destacar",
      xs: 4,
    },
    {
      onClick: () => {
        dispatch(clearEquivalentDocumentReducerAction());
      },
      icon: <ClearIcon />,
      text: "Cancelar",
      xs: 4,
    },
  ];

  const renderIconStepThree = [
    {
      onClick: () => {
        dispatch(nextStepCreateDocumentAction(0));
      },
      icon: <ArrowBackIosIcon />,
      text: "Volver",
      xs: 4,
    },
    {
      onClick: () => {
        dispatch(
          setEquivalentDocDataAction({
            ...equivalentDocumentForm,
            favorite: !equivalentDocumentForm.favorite,
          })
        );
      },
      icon: equivalentDocumentForm.favorite ? <StarIcon /> : <StarBorderIcon />,
      text: "Destacar",
      xs: 4,
    },
    // {
    //   onClick: () => {
    //     dispatch(clearEquivalentDocumentReducerAction());
    //   },
    //   icon: <VisibilityIcon />,
    //   text: "Previsualizar",
    // }, // ! Falta implementar
    {
      onClick: () => {
        dispatch(clearEquivalentDocumentReducerAction());
      },
      icon: <ClearIcon />,
      text: "Cancelar",
      xs: 4,
    },
  ];

  return { renderIconStepOne, renderIconStepTwo, renderIconStepThree };
};
