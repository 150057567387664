import { axiosApiInstance } from '../config/axios-instance';

import {
  API_ENDPOINT_DOCUMENT,
  DIGITS_AFTER_DECIMAL_EQUIVALENT_DOC,
  API_ENDPOINT_ENTERPRISE,
  TIME_UPDATE_REGISTER,
  defaultHeaders,
} from '../config/config';
import { SuccessAlert } from '../helpers/alert.helpers';
import { generateDocumentItem } from '../helpers/calculateDocumentValues';
import { getDataNoteEquivalent } from '../helpers/dataDocumentSaved';
import {
  errorToast,
  successToast,
  warningToast,
} from '../helpers/toast.helpers';

import { types } from '../types/types';
import { getEnterpriseId } from '../utils/dataUserLogin';

import { formatDate } from '../utils/general';
import {
  basePlanExpired,
  buildAddress,
  docGeneratedWithErrorsModalData,
  generatingDocModalData,
  successModalData,
  unexpectedErrorModalData,
} from '../views/EquivalentDocument/utils';
import { cleanArticleCheckedAction } from './articleActions';
import { getCompanyAction } from './basicDataActions';

import {
  changeStatusLoadingGetDetail,
  getCustomerById,
} from './customerAction';
import { setTypeNoteAction } from './documentAction';
import { updateDocumentTotals } from './documentCartAction';

const urlInvoice = `${API_ENDPOINT_DOCUMENT}/document/api/Documento/`;
const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa/`;

/**
 * * Realiza una solicitud POST a la URL especificada con los datos proporcionados y los encabezados predeterminados.
 * @param {string} url - La URL a la que se realizará la solicitud POST.
 * @param {object} data - Los datos que se enviarán en la solicitud POST.
 * @returns {Promise} - Una promesa que se resuelve con la respuesta de la solicitud POST.
 */
const postWithDefaultHeaders = async (url, data) => {
  return await axiosApiInstance.post(url, data, defaultHeaders());
};

//? ====================================
//? Acciones Componentes EquivalentDoc
//? ====================================

/**
 * * Cambia el componente a mostrar y establece los otros en false, si el componente proporcionado es válido.
 * @param {string} componentToShow El nombre del componente que se va a mostrar.
 * @returns {Function} Una función que dispatchea la acción para cambiar el componente, o muestra un mensaje de error si el componente no es válido.
 */
export const changeEquivalentDocComponentAction = (componentToShow) => async (
  dispatch
) => {
  const validComponents = [
    'showGrid',
    'showCreate',
    'showEdit',
    'showDetailNote',
  ];

  if (!validComponents.includes(componentToShow)) {
    dispatch(clearEquivalentDocumentReducerAction());
    return console.error(`Componente "${componentToShow}" no válido.`);
  }
  const initialState = {
    showGrid: false,
    showCreate: false,
    showEdit: false,
    showDetailNote: false,
  };

  const payload = {
    ...initialState,
    [componentToShow]: true,
  };

  dispatch({
    type: types.SHOW_COMPONENTS_EQUIVALENT_DOC,
    payload,
  });
};

/**
 * * Agrega un cliente en un documento equivalente.
 * @param {string} id - El ID del cliente.
 * @returns {Function} - Una función asincrónica que realiza la acción de agregar el cliente en el documento equivalente.
 */
export const addCustomerInEquivalentDocument = (id) => async (dispatch) => {
  try {
    changeStatusLoadingGetDetail(dispatch, true);
    const customer = await getCustomerById(id);
    dispatch(loadCustomerEquivalentDoc({ ...customer, inactiveEdit: false }));
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido consultar información de cliente.',
        error: err,
      },
    });
  } finally {
    changeStatusLoadingGetDetail(dispatch, false);
  }
};

/**
 * * Elimina un documento equivalente de un cliente.
 * @param {Function} dispatch - Función para despachar acciones de Redux.
 * @returns {void}
 */
export const deleteCustomerEquivalentDocAction = () => async (dispatch) => {
  dispatch({
    type: types.LOAD_CUSTOMER_EQUIVALENT_DOC,
    payload: null,
  });
};

/**
 * * Carga inicial para crear un documento equivalente desde el componente.
 * @param {number} branchOfficeID - ID de la sucursal de la empresa.
 * @returns {Function} - Función asíncrona que realiza la carga inicial.
 */
export const initialLoadCreateEquivalentDoc = (branchOfficeID) => async (
  dispatch,
  getState
) => {
  const {
    adsucursalesempresa,
    adnumeracion,
  } = getState().invoiceReducer.aditionaldataenterprise;

  const { typesEquivalentDocument } = getState().configReducer;

  const activeBranches = adsucursalesempresa.filter((s) => s.idstate === 0);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const activeResolutionsDoc = adnumeracion.filter(
    (n) =>
      n.sucursalempresaid === branchOfficeID &&
      n.diantipomodalidadid === 7 &&
      new Date(n.fechainicio) <= currentDate &&
      new Date(n.fechafinal) >= currentDate
  );

  dispatch({
    type: types.SET_INITIAL_LOAD_CREATE_EQUIVALENT,
    payload: {
      activeBranches,
      activeResolutionsDoc,
      activeEquivalentDocTypes: typesEquivalentDocument,
    },
  });
};

/**
 * * Establece los errores del formulario de documento equivalente.
 * @param {Array} errors - Los errores del formulario.
 * @returns {Function} - La función de acción asíncrona.
 */
export const SetEquivalentDocFormErrorsAction = (errors = []) => async (
  dispatch
) => {
  dispatch({
    type: types.SET_ERRORS_FORM_EQUIVALENT_DOC,
    payload: errors,
  });
};

/**
 * * Función que actualiza el estado para avanzar al siguiente paso en la creación de un documento equivalente.
 * @param {number} step - El número del siguiente paso.
 * @returns {Function} - Función asincrónica que dispatchea una acción para actualizar el estado.
 */
export const nextStepCreateDocumentAction = (step) => async (dispatch) => {
  let completed = [];
  if (step >= 2) {
    completed = Array.from({ length: step }, (_, i) => i);
  } else if (step === 1) {
    completed = [step - 1];
  }

  dispatch({
    type: types.SET_CREATE_EQUIVALENT_DOCUMENT_DATA,
    payload: {
      activeStep: step,
      completed: new Set(completed),
    },
  });
};

/**
 * * Establece los datos del documento equivalente.
 * @param {Object} data - Los datos del documento equivalente.
 * @param {Function} dispatch - La función de despacho de Redux.
 * @param {Function} getState - La función para obtener el estado actual de Redux.
 */
export const setEquivalentDocDataAction = (data) => (dispatch, getState) => {
  const { favorite } = getState().equivalentDocReducer.equivalentDocumentForm;

  const dataDoc = {
    ...data,
    favorite: data?.favorite ?? favorite,
  };

  dispatch({
    type: types.SET_EQUIVALENT_DOC_DATA,
    payload: dataDoc,
  });
};

/**
 * *Establece la información para crear los datos del modal.
 * @param {Object} modalInfo - La información del modal.
 * @param {Function} dispatch - La función de despacho de Redux.
 */
export const SetInforForCreatingModalDataAction = (modalInfo) => (dispatch) => {
  dispatch({
    type: types.SET_INFO_FOR_CREATING_MODAL,
    payload: modalInfo,
  });
};

//? ====================================
//? Acciones EquivalentDocAction
//? ====================================

/**
 * * Crea una acción para crear un documento equivalente.
 *
 * @param {Object} data - Los datos del documento equivalente.
 * @returns {Function} - La función de acción asíncrona.
 */
export const createEquivalentDocAction = (data) => async (dispatch) => {
  try {
    dispatch(nextStepCreateDocumentAction(2));
    dispatch(SetInforForCreatingModalDataAction(generatingDocModalData));

    const response = await postWithDefaultHeaders(
      `${urlInvoice}RegistrarDocumentoEquivalente`,
      data
    );

    handleResponse(dispatch, response);
  } catch (error) {
    handleError(dispatch, error);
  }
};
/**
 * * Maneja la respuesta de una solicitud y realiza acciones en función de la respuesta recibida.
 *
 * @param {function} dispatch - La función de despacho utilizada para enviar acciones al store de Redux.
 * @param {object} response - La respuesta recibida de la solicitud.
 */
const handleResponse = (dispatch, response) => {
  if (response.data.statusCode === '201') {
    dispatch(SetInforForCreatingModalDataAction(successModalData(response)));
  } else {
    dispatch(
      SetInforForCreatingModalDataAction(
        docGeneratedWithErrorsModalData(response)
      )
    );
  }
};

/**
 * * Maneja los errores y realiza acciones específicas según el tipo de error.
 * @param {function} dispatch - La función de despacho utilizada para enviar acciones a la tienda.
 * @param {object} error - El objeto de error que se debe manejar.
 */
const handleError = (dispatch, error) => {
  if (
    error?.response?.data?.statusCode === '404' &&
    error?.response?.data?.statusMessage === 'Unauthorized, no quota'
  ) {
    dispatch(SetInforForCreatingModalDataAction(basePlanExpired()));
  } else {
    dispatch(
      SetInforForCreatingModalDataAction(unexpectedErrorModalData(error))
    );
  }
};
/**
 * * Obtiene documentos equivalentes y los procesa para su visualización.
 * @param {Array} equivalentDocs - Documentos equivalentes existentes.
 * @param {Object} requestData - Datos de solicitud para obtener documentos.
 * @param {Function} dispatch - Función de despacho de Redux para actualizar el estado.
 * @returns {Promise<void>} - Promesa que resuelve cuando se completan las operaciones.
 * @throws {Error} - Error si no se pueden obtener los documentos equivalentes.
 */
export const getEquivalentDocsAction = ({
  equivalentDocs,
  requestData,
}) => async (dispatch) => {
  try {
    changeLoadingStatus(dispatch, types.LOADING_GET_EQUIVALENT_DOC, true);

    if (!requestData.LoadMore) cleanDocuments(dispatch);
    const { data } = await axiosApiInstance.post(
      `${urlInvoice}ConsultarListadoDocEquivalente`,
      requestData,
      defaultHeaders()
    );

    const { newDocuments, totalDocuments } = processDocument({
      requestData,
      documents: equivalentDocs,
      data,
      dispatch,
    });

    dispatch({
      type: 'GET_EQUIVALENT_DOC',
      payload: newDocuments,
      payload1: totalDocuments,
    });
  } catch (error) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido obtener listado de documentos equivalentes.',
        error,
      },
    });
  } finally {
    changeLoadingStatus(dispatch, types.LOADING_GET_EQUIVALENT_DOC, false);
  }
};

/**
 * * Selecciona un documento equivalente mediante su ID.
 * @param {string} id - ID del documento equivalente seleccionado.
 * @param {Function} dispatch - Función de despacho de Redux para actualizar el estado.
 */
export const selectEquivalentDocIdAction = (id) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_DOCUMENT_ID,
    payload: id,
  });
};

/**
 * * Actualiza un documento equivalente con los datos proporcionados.
 * @param {Object} dataDoc - Datos del documento a actualizar.
 * @param {Function} dispatch - Función de despacho de Redux para actualizar el estado.
 * @param {Function} getState - Función para obtener el estado actual de Redux.
 * @returns {Promise<void>} - Promesa que resuelve cuando se completa la actualización del documento.
 * @throws {Error} - Error si no se puede actualizar el documento equivalente.
 */
export const UpdateEquivalentDocAction = ({ dataDoc }) => async (
  dispatch,
  getState
) => {
  const typeOperation =
    dataDoc.Operacion === 'PAGADO'
      ? types.LOADING_STATE_EQUIVALENT_DOC_PAID
      : types.LOADING_STATE_EQUIVALENT_DOC;
  try {
    changeLoadingStatus(dispatch, typeOperation, dataDoc.Id);

    const { status } = await axiosApiInstance.post(
      `${urlInvoice}ActualizarDocEquivalente`,
      dataDoc,
      defaultHeaders()
    );

    if (status === 200) {
      const config = defaultHeaders();
      Object.assign(config.headers, {
        pDocumentoId: dataDoc.Id,
      });

      const { result } = (
        await axiosApiInstance.get(
          `${urlInvoice}ConsultarDocEquivalente`,
          config
        )
      ).data;

      updateDocuments({ dataDoc, getState, dispatch, result });
    }
  } catch (error) {
    dispatch({
      type: types.showError,
      payload: {
        message: 'No se ha podido actualizar el documento.',
        error,
      },
    });
    window.location.reload();
  } finally {
    changeLoadingStatus(dispatch, typeOperation, null);
  }
};

/**
 * * Obtiene los detalles de un documento equivalente por su ID y despacha acciones de Redux correspondientes.
 * @param {string} idDocumento - El ID del documento del cual obtener los detalles.
 * @param {Function} dispatch - La función de despacho proporcionada por Redux.
 * @returns {Promise<void>} Una promesa que se resuelve una vez que se completa la acción.
 * @throws {Error} Si hay un error durante el proceso, se despacha una acción de error.
 */
export const getEquivalentDocDetailAction = (idDocumento) => async (
  dispatch
) => {
  try {
    clearErrorState(dispatch);

    changeLoadingStatus(
      dispatch,
      types.LOADING_GET_EQUIVALENT_DOC_DETAIL,
      true
    );

    const config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: idDocumento,
    });

    const { data } = await axiosApiInstance.get(
      `${urlInvoice}ConsultarDocEquivalente`,
      config
    );

    dispatch({
      type: types.GET_EQUIVALENT_DOC_DETAIL,
      payload: data.result,
    });

    const convertData = generateDataDetail(data.result);

    dispatch({
      type: types.SET_SELECTED_DOCUMENT_DATA,
      payload: convertData.selectedDataDoc,
    });

    dispatch({
      type: types.SET_ACQUIRER_INFORMATION,
      payload: convertData.acquirerInformation,
    });

    dispatch({
      type: types.SET_EQUIVALENT_DOCUMENT_ITEMS,
      payload: convertData.equivalentDocumentItems,
    });

    dispatch({
      type: types.SET_ADDITIONAL_DOC_INFO,
      payload: convertData.additionalDocInfo,
    });

    dispatch({
      type: types.SET_ADDITIONAL_INFO_BOX,
      payload: convertData.additionalinfoBox,
    });
  } catch (error) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          'No se ha podido obtener el detalle del documento equivalente.',
        error: {
          ...error,
          name: 'Error Equivalente',
        },
      },
    });
  } finally {
    changeLoadingStatus(
      dispatch,
      types.LOADING_GET_EQUIVALENT_DOC_DETAIL,
      false
    );
  }
};

/**
 * * Acción para limpiar los datos del documento equivalente en el reducer y dejar el estado inicial.
 * @function
 * @returns {Function} Función de acción asincrónica que despacha la acción para limpiar los datos del documento equivalente.
 */
export const clearEquivalentDocumentReducerAction = () => async (dispatch) => {
  dispatch({
    type: types.CLEAR_EQUIVALENT_DOC,
  });

  dispatch({
    type: types.CLEAN_CUSTOMERS_DATA,
  });

  dispatch({
    type: types.CLEAN_DOCUMENT_CART,
  });

  dispatch({
    type: types.DIGITS_AFTER_DECIMAL_TYPE_DOC,
    payload: DIGITS_AFTER_DECIMAL_EQUIVALENT_DOC,
  });

  dispatch({
    type: types.CLEAN_ADITIONAL_DATA_INVOICE,
  });

  cleanArticleCheckedAction()(dispatch);
  clearErrorState(dispatch);
};

/**
 * * Recarga el componente en caso de fallo en la solicitud.
 * @param {string} id - El ID del documento equivalente.
 * @returns {Function} - La función de acción asíncrona.
 */
export const reloadComponentOnRequestFailure = (id) => async (dispatch) => {
  dispatch({
    type: types.GET_EQUIVALENT_DOC_DETAIL,
    payload: {},
  });

  clearErrorState(dispatch);

  await dispatch(getEquivalentDocDetailAction(id));
};

/**
 * * Envia correo electrónico  con reporte documento Equivalente rechazado por DIAN
 * @param {*} data Información usuario y documento
 * @returns
 */
export const sendMailReportDocEquivalentAction = (data) => async (
  dispatch,
  getState
) => {
  try {
    const { equivalentDocFilter } = getState().equivalentDocReducer;

    changeReportLoading(dispatch, data.documentId, true);

    const result = await axiosApiInstance.post(
      `${urlInvoice}${'SendEmailReportDocument'}`,
      data,
      defaultHeaders()
    );

    if (result.status === 200) {
      let total = equivalentDocFilter[0]?.totalRegistros ?? 0;
      let index = equivalentDocFilter.findIndex(
        (c) => c.id === data.documentId
      );
      if (index !== undefined) {
        equivalentDocFilter[index].reporteRechazo = true;
        dispatch({
          type: 'GET_EQUIVALENT_DOC',
          payload: equivalentDocFilter,
          payload1: total,
        });

        successToast(
          `Reporte exitoso documento equivalente numero: ${data.numero}`,
          'Has reportado el caso exitosamente, no te preocupes nosotros nos encargaremos.'
        );
      } else {
        warningToast(
          `No hemos podido actualizar estado reporte del documento soporte numero: $${data.numero}`
        );
      }
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          'No hemos podido enviar el correo electrónico con reporte, por favor contacta al administrador.',
        error: err,
      },
    });
  } finally {
    changeReportLoading(dispatch, data.documentId, false);
  }
};

//?  ====================================
//? Funciones internas EquivalentDocAction
//? ====================================

/**
 * * Actualiza estado loading consulta listado documentos
 * @param {function} dispatch - Función de despacho de Redux.
 * @param {string} type - Tipo de acción a despachar.
 * @param {boolean} status - Nuevo estado de carga.
 */
const changeLoadingStatus = async (dispatch, type, status) => {
  await dispatch({
    type: type,
    payload: status,
  });
};

/**
 * * Procesa las facturas según los datos de filtro y la respuesta de la solicitud.
 * @param {Object} requestData - Datos de filtro.
 * @param {Array} documents - Documentos existentes.
 * @param {Object} data - Respuesta de la solicitud.
 * @param {Function} selectDefaultItem - Función para seleccionar el elemento predeterminado.
 * @returns {Object} - Un objeto con las nuevas facturas y el total.
 */
const processDocument = ({ requestData, documents, data, dispatch }) => {
  const { LoadMore } = requestData;
  const newDocuments = LoadMore ? [...documents, ...data.result] : data.result;
  const totalDocuments = data?.result[0]?.totalRegistros ?? 0;

  if (!LoadMore) {
    dispatch({
      type: types.SET_SELECTED_DOCUMENT_ID,
      payload: newDocuments[0]?.id ?? null,
    });
  }

  return { newDocuments, totalDocuments };
};

/**
 * Actualiza los documentos y detalles de documentos equivalentes.
 *
 * @param {Object} options - Opciones para la actualización de documentos.
 * @param {Object} options.dataDoc - Datos del documento a actualizar.
 * @param {Function} options.getState - Función para obtener el estado actual.
 * @param {Function} options.dispatch - Función para despachar acciones.
 * @param {Object} options.result - Resultado de la actualización.
 */
const updateDocuments = ({ dataDoc, getState, dispatch, result }) => {
  const {
    equivalentDocDetail,
    equivalentDocFilter,
    selectedDocumentData,
    totaldocuments,
  } = getState().equivalentDocReducer;

  const { favorito, estado, estadoId } = result;

  const updatedDocuments = equivalentDocFilter.map((doc) =>
    doc.id === dataDoc.Id
      ? {
          ...doc,
          favorito,
          estado,
          estadoId,
        }
      : doc
  );

  const updatedDetail = {
    ...equivalentDocDetail,
    favorito,
    estado,
    estadoId,
  };

  const selectedDataDoc = {
    ...selectedDocumentData,
    favorite: favorito,
    equivalentDocStatus: estado,
    statusId: estadoId,
  };

  dispatch({
    type: types.GET_EQUIVALENT_DOC,
    payload: updatedDocuments,
    payload1: totaldocuments,
  });

  dispatch({
    type: types.GET_EQUIVALENT_DOC_DETAIL,
    payload: updatedDetail,
  });

  dispatch({
    type: types.SET_SELECTED_DOCUMENT_DATA,
    payload: selectedDataDoc,
  });
};

/**
 * * Limpia la lista de documentos equivalentes en el estado de Redux.
 * @param {Function} dispatch - Función de despacho de Redux para actualizar el estado.
 * @returns {Promise<void>} - Promesa que resuelve cuando se completa la limpieza de documentos.
 */
const cleanDocuments = async (dispatch) => {
  await dispatch({
    type: types.GET_EQUIVALENT_DOC,
    payload: [],
    payload1: 0,
  });
};

/**
 * * Genera un objeto con detalles de un documento a partir de los datos proporcionados.
 * @param {object} data - Los datos del documento.
 * @returns {object} Un objeto con detalles del documento generado.
 */
const generateDataDetail = (data) => {
  const {
    anulacion,
    caja,
    ciudad,
    codigoUnico,
    correoElectronico,
    departamento,
    detalle,
    direccion,
    documento,
    estado,
    estadoId,
    favorito,
    fecha,
    fechaAcuseDian,
    fechaPago,
    formaPago,
    id,
    medioPago,
    nombreCliente,
    numero,
    observacion,
    telefono,
    tipoDocumento,
    tipoDocumentoCliente,
    tipoPersonaCliente,
    tipoRegimenCliente,
    total,
  } = data;

  const selectedDataDoc = {
    amountToPay: total,
    annulment: anulacion,
    dateDocEquivalent: fecha,
    equivalentDocStatus: estado,
    favorite: favorito,
    id: id,
    numberEquivalentDoc: numero,
    statusId: estadoId,
    typeDocEquivalent: tipoDocumento,
  };

  const acquirerInformation = {
    customerAddress: direccion,
    customerCity: ciudad,
    customerDepartment: departamento,
    customerDocument: documento,
    customerEmailAddress: correoElectronico,
    customerLegalName: nombreCliente,
    customerPhoneNumber: telefono,
    documentTypeDescription: tipoDocumentoCliente,
    personType: tipoPersonaCliente,
    regime: tipoRegimenCliente,
  };

  const equivalentDocumentItems = {
    totalEquivalentDocumentsItems: detalle.length,
    equivalentDocumentItems: detalle.map(
      ({
        cantidad,
        cantidadGrados,
        cantidadGramos,
        cantidadMililitros,
        colorImagen,
        id,
        imagen,
        nombreArticulo,
        porcentajeAdv,
        porcentajeIcui,
        porcentajeInc,
        porcentajeIva,
        tarifaIbua,
        tarifaIcl,
        tarifaInpp,
        total,
        valorUnitario,
      }) => {
        return {
          advPercentage: porcentajeAdv,
          degreesAmount: cantidadGrados,
          gramsAmount: cantidadGramos,
          ibuaRate: tarifaIbua,
          iclRate: tarifaIcl,
          icuiPercentage: porcentajeIcui,
          id: id,
          imageColor: colorImagen,
          incPercentage: porcentajeInc,
          inppRate: tarifaInpp,
          itemImage: imagen,
          itemQuantity: cantidad,
          itemTitle: nombreArticulo,
          itemUnitValue: valorUnitario,
          ivaPercentage: porcentajeIva,
          millilitersAmount: cantidadMililitros,
          totalRealItem: total,
        };
      }
    ),
  };

  const additionalDocInfo = {
    acuseDIANDate: fechaAcuseDian
      ? formatDate(new Date(fechaAcuseDian), 'dd/MM/yyyy hh:mm aaaa')
      : '-',
    cude: codigoUnico,
    dateDocEquivalent: formatDate(new Date(fecha), 'dd/MM/yyyy hh:mm aaaa'),
    observationText: observacion,
    paymentMethodDescription: formaPago,
    paymentDate: fechaPago
      ? formatDate(new Date(fechaPago), 'dd/MM/yyyy hh:mm aaaa')
      : '-',
    paymentMethodDetailDescription: medioPago || '-',
  };

  const additionalinfoBox = {
    boxLocation: caja.ubicacion,
    boxPlate: caja.placa,
    boxType: caja.tipo,
    cashier: caja.nombreCajero,
    saleCode: caja.codigoVenta,
    subtotal: caja.subtotal,
  };

  return {
    acquirerInformation,
    additionalDocInfo,
    additionalinfoBox,
    equivalentDocumentItems,
    selectedDataDoc,
  };
};

/**
 * * Limpia el estado de error.
 * @param {function} dispatch - La función de despacho para enviar acciones al store.
 */
const clearErrorState = (dispatch) => {
  dispatch({
    type: types.showError,
    payload: {
      error: null,
      message: '',
      show: false,
    },
  });
};

/**
 * * Carga los datos del cliente en un formato equivalente para el documento.
 * @param {Object} customer - Objeto que contiene los datos del cliente.
 * @param {number} customer.diantipodocumentoidentidadid - ID del tipo de documento de identidad según DIAN.
 * @param {number} customer.diantipopersonaid - ID del tipo de persona según DIAN.
 * @param {number} customer.empresaid - ID de la empresa del cliente.
 * @param {string} customer.ciudaddescripcion - Descripción de la ciudad del cliente.
 * @param {string} customer.correoelectronico - Correo electrónico del cliente.
 * @param {string} customer.departamentodescripcion - Descripción del departamento del cliente.
 * @param {string} customer.direccion - Dirección del cliente.
 * @param {string} customer.direccionid - Dirección completa del cliente.
 * @param {string} customer.documento - Documento del cliente.
 * @param {string} customer.razonsocial - Nombre legal del cliente.
 * @param {string} customer.telefono - Teléfono del cliente.
 * @returns {Object} - Objeto con los datos del cliente en un formato equivalente para el documento.
 */
export const loadCustomerEquivalentDoc = (customer) => {
  const customerData = {
    address: customer.direccion,
    addressId: customer.direccionid,
    cityDescription: customer.ciudaddescripcion,
    customerId: customer.id,
    departmentDescription: customer.departamentodescripcion,
    dianTypeIdentityDocumentId: customer.diantipodocumentoidentidadid,
    dianTypePerson:
      customer.diantipopersonaid === 1 ? 'Persona Jurídica' : 'Persona Natural',
    document: customer.documento,
    email: customer.correoelectronico,
    enterpriseId: customer.empresaid,
    fullAddress: buildAddress(
      customer.direccion,
      customer.ciudaddescripcion,
      customer.departamentodescripcion
    ),
    legalName: customer.razonsocial,
    phone: customer.telefono,
  };
  return {
    type: types.LOAD_CUSTOMER_EQUIVALENT_DOC,
    payload: customerData,
  };
};

/**
 * * Actualiza estado loading reporte documento equivalente
 * @param {*} dispatch
 * @param {*} status
 */
const changeReportLoading = (dispatch, id, status) => {
  let loadingReportDocument = [];
  if (status === true) {
    loadingReportDocument.push(id);
  } else {
    loadingReportDocument = loadingReportDocument.filter((c) => c !== id);
  }
  dispatch({
    type: types.LOADING_REPORT_EQUIVALENT_DOC,
    payload: loadingReportDocument,
  });
};

export const setEquivalentDocTypesAction = (data, reset) => async (
  dispatch,
  getState
) => {
  try {
    changeLoadingStatus(dispatch, types.LOADING_EQUIVALENT_DOC_TYPES, true);
    const { requestCompany } = getState().basicDataReducer;
    const { documento, documentodv } = requestCompany ?? {};

    const dataTypesDoc = {
      EmpresaId: getEnterpriseId(),
      Documento: documento ?? '',
      DigitoVerificacion: documentodv ?? '',
      NombreSoftware: data.nombreSowftwareDE ?? null,
      IdentificadorSoftware: data.identificadorSoftwareDE ?? null,
      PinSoftware: data.pinSoftwareDE ?? null,
      TestIdPosElectronico: data.testIdDE,
      DianTipoDocumentoEquivalenteId: data.tipoDocEquivalenteId,
      DianTipodocumentoEquivalente: data.tipoDocEquivalente.toString(),
    };

    const result = await axiosApiInstance.post(
      `${urlEnterprise}${'PostDataTestDocElectronico'}`,
      dataTypesDoc,
      defaultHeaders()
    );

    if (result.status === 200) {
      dispatch({
        type: types.SET_TEST_ID_EQUIVALENT_DOC_DATA,
        payload: {
          ...dataTypesDoc,
          activo: true,
          name: data.tipoDocEquivalenteDescripcion,
        },
      });
      SuccessAlert(
        'Buen trabajo!!!',
        'Has agregado un nuevo documento equivalente',
        TIME_UPDATE_REGISTER
      );
    }
  } catch (error) {
    errorToast(
      'Upss!!',
      'Ha ocurrido un error agregando el documento equivalente'
    );
    console.log('errorAddingEquivalentDoc', error);
  } finally {
    changeLoadingStatus(dispatch, types.LOADING_EQUIVALENT_DOC_TYPES, false);
    reset();
    dispatch(getCompanyAction());
  }
};

///!

export const loadNoteEquivalentDocDataAction = () => (dispatch, getState) => {
  dispatch(nextStepCreateDocumentAction(1));

  dispatch({
    type: types.SHOW_CATALOG,
    payload: false,
  });

  dispatch(setTypeNoteAction('NC'));

  const { equivalentDocDetail } = getState().equivalentDocReducer;

  const {
    cartDocumentSupport,
    digitsAfterDecimalDoc,
  } = getState().documentCartReducer;

  const {
    adarticulos,
    opdocumentocargos,
    opdocumentodescuentos,
    retiva,
    retica,
    redondeo,
  } = getDataNoteEquivalent(equivalentDocDetail);

  let detaildocument = [];
  adarticulos.forEach((item) => {
    let detail = generateDocumentItem(item);
    detaildocument.push(detail);
  });

  dispatch(
    updateDocumentTotals(
      detaildocument,
      opdocumentocargos,
      opdocumentodescuentos,
      retiva,
      retica,
      redondeo,
      cartDocumentSupport,
      digitsAfterDecimalDoc
    )
  );
};
