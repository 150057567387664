export const getDataNote = (invoice) => {
  let redondeo = invoice.redondeo ?? false;

  let numeracion = {
    id: invoice.numeracionid,
    resolucion: invoice.resolucion,
    fechainicio: invoice.fechainicio,
    fechafinal: invoice.fechafinal,
    prefijo: invoice.prefijo,
    consecinicial: invoice.consecinicial,
    consecfinal: invoice.consecfinal,
    consecactual: invoice.consecactual,
    diantipomodalidadid: 0,
    sucursalempresaid: invoice.sucursalid,
    nombre: invoice.nombrenumeracion,
    descripcion: '',
  };

  let opdocumento = {
    dianformapagoid: invoice.dianformapagoid,
    dianformapagodescripcion: '', //?
    dianmediopagoid: invoice.dianmediopagoid ?? '',
    dianmediopagodescripcion: '', //?
    fecha: invoice.fecha,
    fechapago: invoice.fechapago,
    incluirVendedor: invoice.incluirvendedor,
    numeracionid: invoice.numeracionid,
    numeracion: numeracion,
    observaciones: '',
    rangofecha: 0,
    sucursalid: invoice.sucursalid,
    vendedorid: invoice.vendedorid,
    vendedor: !!invoice.vendedor
      ? {
          ...invoice.vendedor,
          text: `${invoice.vendedor.nombres} ${invoice.vendedor.apellidos}`,
        }
      : null,
    documentoref: invoice.numerodian ?? '',
    documentoreferenciaid: invoice.id,
  };

  const direccionPrincipal = invoice.adclientedirecciones?.find(
    (d) => d.principal === true
  );

  let cliente = {
    documento: invoice.clientedocumento,
    documentodv: invoice.clientedv,
    razonsocial: invoice.clienterazon,
    telefono: invoice.clientetelefono,
    correoelectronico: invoice.clientecorreo,
    diantipoentidadid: 0,
    diantipopersonaid: invoice.diantipopersonaid,
    empresaid: invoice.empresaid,
    favorito: false,
    diantiporegimenid: invoice.diantiporegimenid,
    adclientedireccion: invoice.adclientedirecciones ?? [],
    adcontactos: invoice.adclientecontactos ?? [],
    id: invoice.clienteid,
    direccionid: direccionPrincipal?.id,
    direccion: direccionPrincipal?.direccion,
    ciudadid: direccionPrincipal?.ciudadid,
    ciudaddescripcion: direccionPrincipal?.ciudaddescripcion,
    departamentoid: direccionPrincipal?.departamentoid,
    departamentodescripcion: direccionPrincipal?.departamentodescripcion,
    diantipodocumentoidentidadid: invoice.diantipodocumentoidentidadid,
    vendedorid: '',
    vendedor: null,
    text: '',
  };

  //Retenciones
  let retiva = null;
  let retica = null;
  if (invoice.opdetalledocumento.length > 0) {
    retiva = invoice.opdetalledocumento[0].retiva[0] ?? null;
    retiva = !retiva?.id ? null : retiva;
    retica = invoice.opdetalledocumento[0].retica[0] ?? null;
    retica = !retica?.id ? null : retica;
  }

  let listadoArticulos = [];
  invoice.opdetalledocumento.forEach((item) => {
    let newItem = {
      id: item.articuloid,
      detalleid: item.id,
      codigo: item.codigo,
      imagen: item.imagen,
      color:
        item.adcategoriaarticulo?.length > 0
          ? item.adcategoriaarticulo[0].color
          : '',
      nombrearticulo: item.descripcion,
      cantidad: item.cantidad,
      valor: item.valorunitario,
      neto: item.valorunitario,
      porcentajedescuento: item.porcentajedescuento,
      valordescuento: item.totaldescuento,
      tipodescuento: item.tipodescuento,
      dianunidadmedidaid: item.dianunidadmedidaid,
      adcategoriaarticulo: !!item.adcategoriaarticulo
        ? item.adcategoriaarticulo.map((categoria) => ({
            categoria: { ...categoria },
          }))
        : [],
      valoriva: item.valoriva,
      valorinc: item.valorinc,
      cantidadmililitros:
        item.cantidadMililitros === null
          ? null
          : item.cantidadMililitros / item.cantidad,
      valoribua: item.tarifaIbua === null ? null : item.tarifaIbua,
      weightInGramsInpp:
        item.cantidadGramos === null
          ? null
          : item.cantidadGramos / item.cantidad,
      taxRateUvt: item.tarifaInpp,
      drinkTypeIcl: item.tarifaIcl / item.cantidad,
      alcoholLevelIcl:
        item.cantidadGrados === null ? null : item.cantidadGrados,
      valoricui: item.porcentajeIcui,
      valoradv: item.porcentajeAdv,
      costo: item.costo ?? 0,
      aplicaregalo: item.aplicaregalo,
      esregalo: item.esregalo,
      asumeresponsabilidad:
        item.asumeresponsabilidad === null ? '' : item.asumeresponsabilidad,
      valorreferencia: item.valorreferencia ?? null,
      valoreditable: item.precioeditable ?? false,
      precioeditable: item.precioeditable ?? false,
      idretfte: item.idretfte,
      retfte: item?.retfte[0] ?? null,
      idretica: item.idretica,
      retica: item?.retica[0] ?? null,
      idretiva: item.idretiva,
      retiva: item?.retiva[0] ?? null,
    };
    listadoArticulos.push(newItem);
  });

  //Descuentos
  let descuentos = [];
  invoice.opdocumentodescuentos = invoice.opdocumentodescuentos ?? [];
  invoice.opdocumentodescuentos.forEach((item) => {
    let descuento = {
      motivoid: item.motivoid,
      motivodescripcion: item.motivo,
      descuentoid: item.descuentoid,
      id: item.identificador,
      descripcion: item.descripcion,
      modelodescuento: item.modelodescuento,
      tipodescuento: item.tipodescuento,
      valor: item.valor,
      valoradescontar: item.valoradescontar,
    };
    descuentos.push(descuento);
  });

  //Cargos
  let cargos = [];
  invoice.opdocumentocargos = invoice.opdocumentocargos ?? [];
  invoice.opdocumentocargos.forEach((item) => {
    let cargo = {
      cargoid: item.cargoid,
      id: item.identificador,
      descripcion: item.descripcion,
      tipocargo: item.tipocargo,
      valor: item.valor,
      esglobal: item.cargoid === 14 ? true : false,
      cantidad: item.cantidad,
      valorcalculado: item.valorcalculado,
      valorasumar: item.valorasumar,
    };
    cargos.push(cargo);
  });

  //Datos adicionales
  let opdatosadicionales = invoice.datosAdicionales ?? [];

  //Documentos referencia
  let opdocumentosreferencia = invoice.documentosreferencia ?? [];

  return {
    opdocumento: opdocumento,
    adarticulos: listadoArticulos,
    cliente: cliente,
    opdocumentodescuentos: descuentos,
    opdocumentocargos: cargos,
    redondeo: redondeo,
    retiva,
    retica,
    opdatosadicionales,
    opdocumentosreferencia,
  };
};
export const getDataNoteEquivalent = (equivalentDoc) => {
  //Retenciones
  let retiva = null;
  let retica = null;
  if (equivalentDoc.detalle.length > 0) {
    retiva = null;
    // retiva = !retiva?.id ? null : retiva;
    retica = null;
    // retica = !retica?.id ? null : retica;
  }

  let listadoArticulos = [];
  equivalentDoc.detalle.forEach((item) => {
    let newItem = {
      id: item.id,
      detalleid: item.id,
      // codigo: item.codigo,
      imagen: item.imagen,
      color:
        item.adcategoriaarticulo?.length > 0
          ? item.adcategoriaarticulo[0].color
          : '',
      nombrearticulo: item.descripcion,
      cantidad: item.cantidad,
      valor: item.valorUnitario,
      neto: item.valorUnitario,
      porcentajedescuento: item.porcentajedescuento,
      valordescuento: item.totalDescuento,
      tipodescuento: item.tipodescuento,
      dianunidadmedidaid: item.dianunidadmedidaid,
      adcategoriaarticulo: !!item.adcategoriaarticulo
        ? item.adcategoriaarticulo.map((categoria) => ({
            categoria: { ...categoria },
          }))
        : [],
      valoriva: item.porcentajeIva,
      valorinc: item.porcentajeInc,
      cantidadmililitros:
        item.cantidadMililitros === null
          ? null
          : item.cantidadMililitros / item.cantidad,
      valoribua: item.tarifaIbua === null ? null : item.tarifaIbua,
      weightInGramsInpp:
        item.cantidadGramos === null
          ? null
          : item.cantidadGramos / item.cantidad,
      taxRateUvt: item.tarifaInpp,
      drinkTypeIcl: item.tarifaIcl / item.cantidad,
      alcoholLevelIcl:
        item.cantidadGrados === null ? null : item.cantidadGrados,
      valoricui: item.porcentajeIcui,
      valoradv: item.porcentajeAdv,
      costo: item.costo ?? 0,
      aplicaregalo: item.aplicaregalo,
      esregalo: item.esregalo,
      asumeresponsabilidad:
        item.asumeresponsabilidad === null ? '' : item.asumeresponsabilidad,
      valorreferencia: item.valorreferencia ?? null,
      valoreditable: item.precioeditable ?? false,
      precioeditable: item.precioeditable ?? false,
      idretfte: item.idretfte,
      retfte: null,
      idretica: item.idretica,
      retica: null,
      idretiva: item.idretiva,
      retiva: null,
    };
    listadoArticulos.push(newItem);
  });

  //Descuentos
  let descuentos = [];
  equivalentDoc.opdocumentodescuentos =
    equivalentDoc.opdocumentodescuentos ?? [];
  equivalentDoc.opdocumentodescuentos.forEach((item) => {
    let descuento = {
      motivoid: item.motivoid,
      motivodescripcion: item.motivo,
      descuentoid: item.descuentoid,
      id: item.identificador,
      descripcion: item.descripcion,
      modelodescuento: item.modelodescuento,
      tipodescuento: item.tipodescuento,
      valor: item.valor,
      valoradescontar: item.valoradescontar,
    };
    descuentos.push(descuento);
  });

  //Cargos
  let cargos = [];
  equivalentDoc.opdocumentocargos = equivalentDoc.opdocumentocargos ?? [];
  equivalentDoc.opdocumentocargos.forEach((item) => {
    let cargo = {
      cargoid: item.cargoid,
      id: item.identificador,
      descripcion: item.descripcion,
      tipocargo: item.tipocargo,
      valor: item.valor,
      esglobal: item.cargoid === 14 ? true : false,
      cantidad: item.cantidad,
      valorcalculado: item.valorcalculado,
      valorasumar: item.valorasumar,
    };
    cargos.push(cargo);
  });

  //Datos adicionales
  let opdatosadicionales = equivalentDoc.datosAdicionales ?? [];

  //Documentos referencia
  let opdocumentosreferencia = equivalentDoc.documentosreferencia ?? [];

  return {
    adarticulos: listadoArticulos,
    opdocumentodescuentos: descuentos,
    opdocumentocargos: cargos,
    redondeo: true, //! Cambiar
    retiva,
    retica,
    opdatosadicionales,
    opdocumentosreferencia,
  };
};

export const getDataNoteDocSupport = (docSupport) => {
  let redondeo = docSupport.redondeo ?? false;

  let numeracion = {
    id: docSupport.numeracionId,
    resolucion: docSupport.resolucion,
    fechainicio: docSupport.fechainicio,
    fechafinal: docSupport.fechafinal,
    prefijo: docSupport.prefijo,
    consecinicial: docSupport.consecinicial,
    consecfinal: docSupport.consecfinal,
    consecactual: docSupport.consecactual,
    diantipomodalidadid: docSupport.diantipomodalidadid,
    // sucursalempresaid: docSupport.sucursalid,
    nombre: docSupport.nombrenumeracion,
    descripcion: '',
  };

  let opdocumento = {
    dianformapagoid: docSupport.dianFormaPagoId,
    dianformapagodescripcion: '', //?
    dianmediopagoid: docSupport.dianmediopagoid ?? '',
    dianmediopagodescripcion: '', //?
    fecha: docSupport.fecha,
    fechapago: docSupport.fechapago,
    numeracionid: docSupport.numeracionId,
    numeracion: numeracion,
    observaciones: '',
    rangofecha: 0,
    // sucursalid: docSupport.sucursalid,
    documentoref: docSupport.numerodian ?? '',
    documentoreferenciaid: docSupport.id,
  };

  let cliente = {
    documento: docSupport.documentoProveedor,
    documentodv: docSupport.documentoDv,
    razonsocial: docSupport.razonsocial,
    telefono: docSupport.telefono,
    correoelectronico: docSupport.correoElectronico,
    diantipoentidadid: 0,
    diantipopersonaid: docSupport.diantipopersonaid,
    empresaid: docSupport.empresaId,
    favorito: false,
    diantiporegimenid: docSupport.diantiporegimenid,
    adclientedireccion: docSupport.direccion ?? [],
    id: docSupport.idProveedor,
    // direccion: docSupport.direccion,
    ciudadid: docSupport.daneciudadid,
    ciudaddescripcion: docSupport.ciudad,
    // departamentoid: direccionPrincipal.departamentoid,
    departamentodescripcion: docSupport.departamento,
    diantipodocumentoidentidadid: docSupport.tipoDocumentoProveedor,
  };

  //Retenciones
  let retiva = null;
  let retica = null;
  if (docSupport.opdetalledocumento.length > 0) {
    retiva = docSupport.opdetalledocumento[0]?.retiva[0] ?? null;
    retiva = !retiva?.id ? null : retiva;
    // retica = docSupport.opdetalledocumento[0].retica[0] ?? null;
    // retica = !retica?.id ? null : retica;
  }

  let listadoArticulos = [];
  docSupport.opdetalledocumento.forEach((item) => {
    let newItem = {
      codigo: item.codigo,
      nombrearticulo: item.descripcion,
      cantidad: item.cantidad,
      valor: item.valorUnitario,
      neto: item.valorUnitario,
      porcentajedescuento: item.porcentajeDescuento,
      valordescuento: item.totalDescuento,
      tipodescuento: item.tipoDescuento,
      dianunidadmedidaid: item.dianUnidadMedidaId,
      valoriva: item.porcentajeIva,
      costo: item.costo ?? 0,
      valorreferencia: item.valorReferencia ?? null,
      idretfte: item.idRetRenta,
      retfte: item?.retrenta[0] ?? null,
      idretiva: item.idRetIva,
      retiva: item?.retiva[0] ?? null,
    };
    listadoArticulos.push(newItem);
  });

  //   //Descuentos
  //   let descuentos = [];
  //   invoice.opdocumentodescuentos = invoice.opdocumentodescuentos ?? [];
  //   invoice.opdocumentodescuentos.forEach((item) => {
  //     let descuento = {
  //       motivoid: item.motivoid,
  //       motivodescripcion: item.motivo,
  //       descuentoid: item.descuentoid,
  //       id: item.identificador,
  //       descripcion: item.descripcion,
  //       modelodescuento: item.modelodescuento,
  //       tipodescuento: item.tipodescuento,
  //       valor: item.valor,
  //       valoradescontar: item.valoradescontar,
  //     };
  //     descuentos.push(descuento);
  //   });

  //Cargos
  //   let cargos = [];
  //   invoice.opdocumentocargos = invoice.opdocumentocargos ?? [];
  //   invoice.opdocumentocargos.forEach((item) => {
  //     let cargo = {
  //       cargoid: item.cargoid,
  //       id: item.identificador,
  //       descripcion: item.descripcion,
  //       tipocargo: item.tipocargo,
  //       valor: item.valor,
  //       esglobal: item.cargoid === 14 ? true : false,
  //       cantidad: item.cantidad,
  //       valorcalculado: item.valorcalculado,
  //       valorasumar: item.valorasumar,
  //     };
  //     cargos.push(cargo);
  //   });

  //Datos adicionales
  //   let opdatosadicionales = invoice.datosAdicionales ?? [];

  //Documentos referencia
  //   let opdocumentosreferencia = invoice.documentosreferencia ?? [];

  return {
    opdocumento: opdocumento,
    adarticulos: listadoArticulos,
    cliente: cliente,
    opdocumentodescuentos: [],
    opdocumentocargos: [],
    redondeo: redondeo,
    retiva,
    retica,
    // opdatosadicionales,
    // opdocumentosreferencia,
  };
};

export const getDataEditDocument = (document) => {
  let redondeo = document.redondeo ?? false;

  let numeracion = {
    id: document.numeracionid,
    resolucion: document.resolucion,
    fechainicio: document.fechainicio,
    fechafinal: document.fechafinal,
    prefijo: document.prefijo,
    consecinicial: document.consecinicial,
    consecfinal: document.consecfinal,
    consecactual: document.consecactual,
    diantipomodalidadid: 0,
    sucursalempresaid: document.sucursalid,
    nombre: document.nombrenumeracion,
    descripcion: '',
  };

  let opdocumento = {
    id: document.id,
    numero: document.numero,
    tipoaceptacionid: document.tipoaceptaciondocumentoid,
    dianformapagoid: document.dianformapagoid,
    formaPago: document.dianformapagoid === 1 ? '2' : '1',
    dianformapagodescripcion: '',
    dianmediopagoid: document.dianmediopagoid ?? '',
    dianmediopagodescripcion: '',
    fecha: document.fecha,
    fechapago: document.fechavigencia,
    incluirVendedor: document.incluirvendedor,
    numeracionid: document.numeracionid,
    numeracion: numeracion,
    observaciones: '',
    rangofecha: 0,
    sucursalid: document.sucursalid,
    vendedorid: document.vendedorid,
    vendedor: !!document.vendedor
      ? {
          ...document.vendedor,
          text: `${document.vendedor.nombres} ${document.vendedor.apellidos}`,
        }
      : null,
  };

  let adclientedirecciones = !!document.adclientedirecciones
    ? document.adclientedirecciones
    : [];

  adclientedirecciones = adclientedirecciones.map((item) => ({
    ...item,
    ciudaddescripcion: item.nombmun,
    departamentodescripcion: item.nombdep,
  }));

  let direccionPrincipal =
    adclientedirecciones.find((d) => d.principal === true) ?? null;

  let cliente = {
    documento: document.clientedocumento,
    documentodv: document.clientedv,
    razonsocial: document.clienterazon,
    telefono: document.clientetelefono,
    correoelectronico: document.clientecorreo,
    diantipoentidadid: 0,
    diantipopersonaid: document.diantipopersonaid,
    empresaid: document.empresaid,
    favorito: false,
    diantiporegimenid: document.diantiporegimenid,
    adclientedireccion: adclientedirecciones.filter(
      (d) => d.principal !== true
    ),
    adcontactos: document.adclientecontactos ?? [],
    id: document.clienteid,
    direccionid: direccionPrincipal?.id,
    direccion: direccionPrincipal?.direccion,
    ciudadid: direccionPrincipal?.ciudadid,
    ciudaddescripcion: direccionPrincipal?.ciudaddescripcion,
    departamentoid: direccionPrincipal?.departamentoid ?? 0,
    departamentodescripcion: direccionPrincipal?.departamentodescripcion,
    diantipodocumentoidentidadid: document.diantipodocumentoidentidadid,
    vendedorid: '',
    vendedor: null,
    text: '',
  };

  //Retenciones
  let retiva = null;
  let retica = null;
  if (document.opdetalledocumento.length > 0) {
    retiva = document.opdetalledocumento[0].retiva[0] ?? null;
    retiva = !retiva?.id ? null : retiva;
    retica = document.opdetalledocumento[0].retica[0] ?? null;
    retica = !retica?.id ? null : retica;
  }

  let listadoArticulos = [];
  document.opdetalledocumento.forEach((item) => {
    let newItem = {
      id: item.articuloid,
      detalleid: item.id,
      codigo: item.codigo,
      imagen: item.imagen,
      color:
        item.adcategoriaarticulo?.length > 0
          ? item.adcategoriaarticulo[0].color
          : '',
      nombrearticulo: item.descripcion,
      cantidad: item.cantidad,
      valor: item.valorunitario,
      neto: item.valorunitario,
      porcentajedescuento: item.porcentajedescuento,
      valordescuento: item.totaldescuento,
      tipodescuento: item.tipodescuento,
      dianunidadmedidaid: item.dianunidadmedidaid,
      adcategoriaarticulo: !!item.adcategoriaarticulo
        ? item.adcategoriaarticulo.map((categoria) => ({
            categoria: { ...categoria },
          }))
        : [],
      valoriva: item.valoriva,
      valorinc: item.valorinc,
      cantidadmililitros:
        item.cantidadMililitros === null
          ? null
          : item.cantidadMililitros / item.cantidad,
      valoribua: item.tarifaIbua === null ? null : item.tarifaIbua,
      weightInGramsInpp:
        item.cantidadGramos === null
          ? null
          : item.cantidadGramos / item.cantidad,
      taxRateUvt: item.tarifaInpp,
      drinkTypeIcl: item.tarifaIcl / item.cantidad,
      alcoholLevelIcl:
        item.cantidadGrados === null ? null : item.cantidadGrados,
      valoricui: item.porcentajeIcui,
      valoradv: item.porcentajeAdv,
      costo: item.costo ?? 0,
      aplicaregalo: item.aplicaregalo,
      esregalo: item.esregalo,
      asumeresponsabilidad:
        item.asumeresponsabilidad === null ? '' : item.asumeresponsabilidad,
      valorreferencia: item.valorreferencia ?? null,
      valoreditable: item.precioeditable ?? false,
      precioeditable: item.precioeditable ?? false,
      idretfte: item.idretfte,
      retfte: item?.retfte[0] ?? null,
      idretica: item.idretica,
      retica: item?.retica[0] ?? null,
      idretiva: item.idretiva,
      retiva: item?.retiva[0] ?? null,
    };
    listadoArticulos.push(newItem);
  });

  //Descuentos
  let descuentos = [];
  document.opdocumentodescuentos = document.opdocumentodescuentos ?? [];
  document.opdocumentodescuentos.forEach((item) => {
    let descuento = {
      motivoid: item.motivoid,
      motivodescripcion: item.motivo,
      descuentoid: item.descuentoid,
      id: item.identificador,
      descripcion: item.descripcion,
      modelodescuento: item.modelodescuento,
      tipodescuento: item.tipodescuento,
      valor: item.valor,
      valoradescontar: item.valoradescontar,
    };
    descuentos.push(descuento);
  });

  //Cargos
  let cargos = [];
  document.opdocumentocargos = document.opdocumentocargos ?? [];
  document.opdocumentocargos.forEach((item) => {
    let cargo = {
      cargoid: item.cargoid,
      id: item.identificador,
      descripcion: item.descripcion,
      tipocargo: item.tipocargo,
      valor: item.valor,
      esglobal: item.cargoid === 14 ? true : false,
      cantidad: item.cantidad,
      valorcalculado: item.valorcalculado,
      valorasumar: item.valorasumar,
    };
    cargos.push(cargo);
  });

  //Datos adicionales
  let datosadicionales = {};
  let opdatosadicionales = document.datosAdicionales ?? [];
  for (let i = 0; i < opdatosadicionales.length; i++) {
    const item = opdatosadicionales[i];
    datosadicionales = {
      ...datosadicionales,
      [item.codigo]: item.valor,
    };
  }

  //Documentos referencia
  let opdocumentosreferencia = [];

  const direccionclienteid = document.direccionclienteid ?? 0;
  const contactoclienteid = document.contactoclienteid ?? 0;

  return {
    opdocumento: opdocumento,
    adarticulos: listadoArticulos,
    cliente: cliente,
    opdocumentodescuentos: descuentos,
    opdocumentocargos: cargos,
    redondeo: redondeo,
    retiva,
    retica,
    opdatosadicionales,
    datosadicionales,
    opdocumentosreferencia,
    direccionclienteid,
    contactoclienteid,
  };
};
