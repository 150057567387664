import React from "react";

import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

import { FormatDecimal } from "../../../../../components/common/formatDecimal";
import CustomProgress from "../../../../../components/Progress/progress.component";

import { formatDate } from "../../../../../utils/general";

import { getStyleStatusItem } from "../../../../documentSupport/getStyleStatusItem";

const useStyles = makeStyles((theme) => ({
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  marginBottom: {
    marginBottom: 5,
  },
  amountToPay: {
    textAlign: "left",
  },
  containerTagType: {
    alignItems: "center",
    border: "1px #9DE1FE solid",
    borderRadius: 8,
    display: "inline-flex",
    gap: 8,
    height: "100%",
    justifyContent: "flex-start",
    padding: "2px 8px",
  },
  tagType: {
    color: "#9DE1FE",
    fontSize: 12,
    fontWeight: "200",
    wordWrap: "break-all",
  },
}));

export const SelectedDocumentData = () => {
  const classes = useStyles();

  const { selectedDocumentData, loadingStateEquivalentDoc } = useSelector(
    (state) => state.equivalentDocReducer
  );

  const {
    amountToPay,
    annulment,
    dateDocEquivalent,
    equivalentDocStatus,
    favorite,
    id,
    numberEquivalentDoc,
    statusId,
    typeDocEquivalent,
  } = selectedDocumentData;

  const styleState = getStyleStatusItem({
    anulacion: annulment,
    estadoId: statusId,
  });

  const renderIcon = () => {
    if (loadingStateEquivalentDoc === id) {
      return <CustomProgress size={20} />;
    } else if (favorite) {
      return <StarIcon />;
    } else {
      return <StarBorderIcon />;
    }
  };

  return (
    <>
      <Grid
        container
        className={`${classes.marginBottom} animate__animated animate__fadeIn animate__faster`}
      >
        <Grid item xs={6} className="detailSupportNumber">
          No. {numberEquivalentDoc}
        </Grid>
        <Grid item xs={6} className={classes.alignRight}>
          {renderIcon()}
        </Grid>
      </Grid>
      {/* Fin Seccion 1 */}
      <Grid container className={`${classes.marginBottom}`}>
        <Grid item xs={6} className={`${classes.amountToPay} ${styleState}`}>
          <FormatDecimal value={amountToPay} prefix={"$"} size="small" />
        </Grid>
        <Grid item xs={6} className={`${classes.alignRight}  ${styleState}`}>
          {annulment ? "Anulada" : equivalentDocStatus}
        </Grid>
      </Grid>
      {/* Fin Seccion 2 */}
      <Grid container className={`${classes.marginBottom}`}>
        <Grid item xs={6} className="dataInvoiceGrid">
          {formatDate(dateDocEquivalent, "dd/MM/yyyy hh:mm aaaa")}
        </Grid>
        <Grid item xs={6} className={`${classes.alignRight} `}>
          <div className={classes.containerTagType}>
            <div className={classes.tagType}>{typeDocEquivalent}</div>
          </div>
        </Grid>
      </Grid>
      {/* Fin Seccion 3 */}
    </>
  );
};
