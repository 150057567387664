import { getDetailDocument } from "../../../helpers/generateRequestDocument";

export const generateRequestEquivalentDoc = ({
  equivalentDoc,
  totalsCart,
  customerDoc,
}) => {
  const {
    boxLocation,
    boxPlate,
    boxType,
    cashier,
    dianPaymentMethodID,
    documentDate,
    favorite,
    numericalID,
    observations,
    paymentDate,
    paymentMethod,
    saleCode,
    subtotal,
  } = equivalentDoc;

  let {
    opdetalledocumento,
    redondeo,
    totalfacturaReal,
    valoradvreal,
    valorapagar,
    valorapagardian,
    valorbaseimponible,
    valorbruto,
    valorcargos,
    valordescuento,
    valordescuentoreal,
    valoribuareal,
    valoriclreal,
    valoricuireal,
    valorinc,
    valorincreal,
    valorinppreal,
    valoriva,
    valorivareal,
    valorotrosdescuentos,
    valorretfte,
    valorretica,
    valorretiva,
    valortotal,
  } = totalsCart;

  const { customerId, addressId, enterpriseId } = customerDoc;
  let generateddocument = {
    Opdocumento: {
      Archivovisualizar: "EQUIVALENT DOCUMENT",
      Clienteid: customerId,
      Contactoclienteid: null, //! Pendiente de implementar
      Decimales: 2,
      Dianformapagoid: paymentMethod === "1" ? 2 : 1,
      Dianmediopagoid: paymentMethod === "2" ? dianPaymentMethodID : null,
      Diantipodocumentoelectronicoid: 7,
      Direccionclienteid: addressId,
      Empresaid: enterpriseId,
      Estadodocumentoid: 0,
      Favorito: favorite,
      Fecha: documentDate,
      Fechapago: !!paymentDate && paymentMethod === "1" ? paymentDate : null,
      Generardocumentopacientes: false,
      Numeracionid: numericalID,
      Origendocumentoid: 1,
      Redondeo: redondeo,
      Responsabilidadtributariaid: null,
      Subtotal: valorbruto,
      Textoobservacion: observations,
      Tipoaceptaciondocumentoid: 1,
      Tipoacusedocumentoid: 3,
      TotalBaseImponible: valorbaseimponible,
      TotalfacturaReal: totalfacturaReal,
      Valoradv: valoradvreal === null ? null : valoradvreal,
      Valoranticipo: 0,
      Valorapagar: valorapagar,
      Valorapagardian: valorapagardian,
      Valorcargodocumento: valorcargos,
      Valorcargos: valorcargos,
      Valordescuento: valordescuento,
      Valordescuentodocumento: valorotrosdescuentos,
      Valordescuentoreal: valordescuentoreal,
      Valoribua: valoribuareal === null ? null : valoribuareal,
      Valoricl: valoriclreal === null ? null : valoriclreal,
      Valoricui: valoricuireal === null ? null : valoricuireal,
      Valorinc: valorinc,
      Valorincreal: valorincreal,
      Valorinpp: valorinppreal === null ? null : valorinppreal,
      Valoriva: valoriva,
      Valorivareal: valorivareal,
      Valorretfte: valorretfte,
      Valorretica: valorretica,
      Valorretiva: valorretiva,
      Valortotal: valortotal,
    },
    Lstopdetalledocumento: getDetailDocument(opdetalledocumento),
    Datoscaja: {
      Codigoventa: saleCode,
      Nombrecajero: cashier,
      Placa: boxPlate,
      Subtotal: subtotal,
      Tipo: boxType,
      Ubicacion: boxLocation,
    },
    Lstcargos: [],
    Lstdescuentos: [],
    Datosadicionales: [],
    Documentosreferencia: [],
    Oppacientes: [],
  };

  return generateddocument;
};
