import React from "react";

import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { updateItemTotalAction } from "../../../../actions/documentCartAction";

import InputSmall from "../../../../components/input/inputSmall.component";

import { convertStringToDecimal } from "../../../../utils/convertStringToDecimal";
import { validateUnitValueItem } from "../../../../helpers/quantityUnitValue";

export const UnitValueSection = ({
  handleUpdateForm,
  isGift,
  itemDoc,
  quantity,
  unitValue,
  validator,
}) => {
  const dispatch = useDispatch();

  const { valoreditable, valornetoarticulo } = itemDoc;

  /**
   * * Indica si el campo disabledAndLock está habilitado o bloqueado.
   * @type {boolean}
   */
  const disabledAndLock = valoreditable
    ? isGift
    : valornetoarticulo > 0
    ? true
    : isGift;

  /**
   * * Maneja el cambio del valor unitario de un ítem del carrito.
   * @param {Object} event - El evento de cambio.
   */
  const handleChangeUnitValue = ({ target }) => {
    const value = validateUnitValueItem(target.value, quantity);
    handleUpdateForm({
      unitValue: value,
    });
  };

  /**
   * * Maneja el evento onBlur del campo de valor unitario.
   * @param {Event} event - El evento onBlur.
   */
  const handleOnBlurUnitValue = ({ target }) => {
    const newvalue = convertStringToDecimal(target.value);
    let newdata = { ...itemDoc, valorneto: newvalue };

    if (target.value.trim().length === 0) handleUpdateForm({ unitValue: 0 });

    dispatch(updateItemTotalAction(newdata));
  };

  return (
    <Grid item lg={6} xs={12}>
      <InputSmall
        disabled={disabledAndLock}
        format={"money"}
        label={"Valor Unitario"}
        lockIcon={disabledAndLock}
        maxLength={21}
        name={"unitValue"}
        nameValidator={"unitValue"}
        onBlur={handleOnBlurUnitValue}
        onChange={handleChangeUnitValue}
        validateOptions={isGift ? "required" : "required|noZero"}
        validator={validator}
        value={unitValue}
        variant={"standard"}
      />
    </Grid>
  );
};
