import React from "react";

import CustomProgress from "../../../components/Progress/progress.component";

const initialModalDataCreate = {
  body: "",
  closeElement: "",
  closeFromModalBody: false,
  modalImage: "",
  onCloseElement: 0,
  open: false,
  subtitle: "",
  title: "",
  type: "",
};

const generatingDocModalData = {
  body: (
    <>
      {" "}
      <CustomProgress />
      {"Procesando"}{" "}
    </>
  ),
  closeElement: "",
  closeFromModalBody: false,
  modalImage: "noimagen",
  onCloseElement: 0,
  open: true,
  subtitle: "Generando documento, por favor espere...",
  title: "",
  type: "success",
};

const successModalData = (response) => ({
  body: (
    <div>
      <div>
        {response.data.statusMessage.indexOf("Rechazo") !== -1
          ? "Documento generado con error al enviar a Dian"
          : "Proceso Exitoso"}
      </div>
      <div className="fenomenalWhiteLittle">
        {response.data.statusMessage}{" "}
        <span className="fenomenalBlueLittle">No. {response.data.result}</span>
      </div>
    </div>
  ),
  closeElement: "Saltar",
  closeFromModalBody: true,
  modalImage: "success",
  onCloseElement: 1,
  open: true,
  subtitle: "Has generado un nuevo documento equivalente",
  title: "Fenomenal!!!",
  type: "success",
});

const docGeneratedWithErrorsModalData = (response) => ({
  body: (
    <div>
      <div>{"Documento generado con error al enviar a Dian"}</div>
      <div className="fenomenalWhiteLittle">
        {response.data.statusMessage} Documento Generado:{" "}
        <span className="fenomenalBlueLittle">No. {response.data.result}</span>
      </div>
    </div>
  ),
  closeElement: "Saltar",
  closeFromModalBody: false,
  modalImage: "warning",
  onCloseElement: 1,
  open: true,
  subtitle: "Ocurrió algo inesperado",
  title: "Upss...!!!",
  type: "warning",
});

const unexpectedErrorModalData = (response) => ({
  type: "warning",
  title: "Upss...!!!",
  subtitle: "Ocurrió algo inesperado",
  body: (
    <div>
      <div>
        {JSON.stringify(
          response === undefined
            ? "Error inesperado"
            : response?.data?.statusMessage
        )}
      </div>
    </div>
  ),
  modalImage: "warning",
  open: true,
  closeElement: "Saltar",
  onCloseElement: 1,
  closeFromModalBody: true,
});

const basePlanExpired = () => ({
  modalType: "warning",
  title: "Tu plan de Colfactura venció",
  body: (
    <div>
      <p
        style={{
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {
          "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
        }
      </p>
      <p
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
      </p>
    </div>
  ),
  modalImage: "warning",
  open: true,
  textButton: "Comprar",
  closeElement: "Skip",
  closeFromModalBody: true,
});

export {
  docGeneratedWithErrorsModalData,
  generatingDocModalData,
  initialModalDataCreate,
  successModalData,
  unexpectedErrorModalData,
  basePlanExpired,
};
