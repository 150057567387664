import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";

import ArchiveModal from "../../components/modal/archiveModal.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ResponseModal from "../../components/modal/responseModal.component";
import ResponseRedirectModal from "../../components/modal/responseRedirectModal.component";
import SimpleModal from "../../components/modal/simpleModal.component";
import InvoiceTypeNote from "../invoice/invoice-typeNote.view";
import InvoiceAnnulmentNote from "../invoice/invoice-annulmentNote";
import { DocumentCartManage } from "./cart/documentCartManage.view.js";
import { DocumentReview } from "./review/documentReview.view.js";
import CustomProgress from "../../components/Progress/progress.component";

import {
  cleanLicenseBaseAction,
  createNote,
  createNoteAnnulmentAction,
  getDocumentPreviewAction,
  getReasonNotes,
  updateOpenModalItem,
} from "../../actions/invoiceActions";
import {
  cleanDocumentCreateAction,
  standOutDocumentAction,
  setTypeNoteAction,
} from "../../actions/documentAction.js";
import { addReasonNoteAction } from "../../actions/documentCartAction";

import { ConfirmAlert, WarningAlert } from "../../helpers/alert.helpers";
import { generateRequestNote } from "../../helpers/generateRequestDocument";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../helpers/toast.helpers";
import { checkTotalDocument } from "../../helpers/validateDocument.js";

import { CLAIM_DOCUMENTS, MESSAGE_NEXT_CONFIRM } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: "18px",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  activeButton: {
    borderBottom: "1px solid #50C1EE",
    paddingBottom: "10px",
  },
  button: {
    borderRadius: "15px !important",
    padding: 0,
  },
  label: {
    flexDirection: "column",
    fontSize: 12,
    color: "white",
  },
  labelHorizontal: {
    flexDirection: "row",
    fontSize: 12,
    color: "white",
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#4F4F4F",
    },
  },
  line: {
    borderColor: "#4F4F4F",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {" "}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{" "}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2CC63E",
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#4F4F4F",
  },
  completed: {
    color: "#4F4F4F",
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2CC63E",
    borderRadius: "50%",
    padding: 5,
  },
});

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function getSteps() {
  return ["Información de Nota", "Artículos", "Revisar Nota"];
}

export const UpdateDocumentView = ({ HandleBack, invoiceBD = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [base64File, setBase64File] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState(new Set());
  const [modalResponse, setModalResponse] = React.useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
    closeFromModalBody: false,
  });

  const initialModal = {
    modalType: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
  };

  const basePlanExpired = {
    modalType: "warning",
    title: "Tu plan de Colfactura venció",
    body: (
      <div>
        <p
          style={{
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {
            "No cuentas con un plan activo, es necesario renovarlo para que puedas seguir emitiendo documentos. "
          }
        </p>
        <p
          style={{
            marginTop: 10,
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {"Si quieres comprar tu nuevo plan, haz clic en “Comprar”."}
        </p>
      </div>
    ),
    modalImage: "warning",
    open: true,
    textButton: "Comprar",
    closeElement: "Skip",
    closeFromModalBody: true,
  };

  const steps = getSteps();
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [openModalFile, setOpenModalFile] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [openModalAnnulment, setOpenModalAnnulment] = React.useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = React.useState(
    initialModal
  );
  const [loadingNoteAnnulment, setLoadingNoteAnnulment] = React.useState(false);
  const { openModalItem: modal, licenseBase } = useSelector(
    (state) => state.invoiceReducer
  );
  const documentdata = useSelector((state) => state.documentReducer);
  const {
    favorito,
    opdocumentosreferencia,
    tiponotaactiva,
    opdocumento,
  } = documentdata;
  const { documentoref } = opdocumento;
  const totals = useSelector((state) => state.documentCartReducer);
  const {
    showCatalog,
    valorapagar,
    opdetalledocumento,
    valorcargosaplicados,
    valorbase,
    observacionesnota,
    motivonota,
  } = totals;

  /**
   * Cargue incial
   */
  useEffect(() => {
    dispatch(getReasonNotes());

    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < 1; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
  }, [dispatch]);

  /**
   * Desmontar componente
   */
  useEffect(() => {
    return () => {
      dispatch(cleanDocumentCreateAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (loading) {
      setModalResponse({
        type: "success",
        title: "",
        subtitle: "Generando documento, por favor espere...",
        body: (
          <div>
            <div>
              <Fragment>
                {" "}
                <CustomProgress />
                {"Procesando"}{" "}
              </Fragment>
            </div>
          </div>
        ),
        modalImage: "noimagen",
        open: true,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseBase]);

  /**
   * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
  };

  /**
   * Avanza paso siguiente
   */
  const handleNextStep = (step) => {
    if (step === 2) {
      if (!validateStepTwo()) return;
    }

    //Marca paso 1 como completo
    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < step; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  /**
   * Regresa a paso anterior
   * @param {*} step
   */
  const handleBackStep = (step) => {
    if (step === 1) {
      setOpenModalEdit(true);
    } else {
      setActiveStep(step - 1);
    }
  };

  /**
   * Valida información carrito
   * @returns
   */
  const validateStepTwo = () => {
    let errormessage = [];

    if (opdetalledocumento.length === 0) {
      WarningAlert("Upsss...!", "Debes seleccionar al menos un articulo");
      return false;
    }

    const valid = checkTotalDocument(valorapagar, opdocumentosreferencia);
    if (!valid) {
      errormessage.push(
        "El valor del anticipo es superior al valor de la nota. Por favor verifique la información."
      );
      warningToast(
        "Valor anticipo no valido.",
        "El valor del anticipo es superior al valor de la nota. Por favor verifique la información."
      );
    }

    if (!motivonota)
      errormessage.push("Debes seleccionar el motivo de la edición.");

    if (observacionesnota.trim().length === 0)
      errormessage.push("Debes digitar la observación de la nota.");

    if (errormessage.length > 0) {
      let message = errormessage.join("               ");
      WarningAlert("Upsss...!", message);
      return false;
    }

    return true;
  };

  /**
   * Desctacar documento
   */
  const handleStandOut = () => {
    dispatch(standOutDocumentAction());
  };

  /**
   * Marca paso a completo
   * @param {*} step
   * @returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  /**
   * Cancelar creación documento
   */
  const handleCancelDocument = () => {
    HandleBack();
  };

  /**
   * Genera previsualización de documento
   * @param {*} e
   */
  const handlePreviewDocument = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      setOpenModalFile(true);
      setLoadingFile(true);
      let note = generateRequestNote(documentdata, totals);
      dispatch(getDocumentPreviewAction(note, readResponseGetFile));
    }
  };

  /**
   * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  /**
   * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  /**
   * Envia documento para registro como nota
   * @param {*} e
   */
  const handleSaveNote = (e) => {
    e.preventDefault();

    if (validateDocument()) {
      const senddocument = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          const note = generateRequestNote(documentdata, totals);
          dispatch(createNote(note, readResponseSaveNote, readErrorSaveNote));
        }
      };
      ConfirmAlert(
        "Al editar la factura se generará una Nota " +
          (motivonota?.tipo === "ND" ? "Debito" : "Crédito") +
          " ¿Esta seguro que desea hacerlo?",
        senddocument,
        MESSAGE_NEXT_CONFIRM
      );
    }
  };

  /**
   * Valida información de documento antes de guardar
   */
  const validateDocument = () => {
    let errorMessages = [];
    if (opdetalledocumento.length === 0) {
      errorMessages.push("Debes seleccionar al menos un producto.");
    } else {
      if (valorapagar < 0)
        errorMessages.push(
          "El valor a pagar de la factura no puede ser negativo."
        );

      //Campos obligatorios por item
      let valid = true;
      opdetalledocumento.forEach((item) => {
        if (item.esregalo) {
          if (!item.asumeresponsabilidad || !item.valorreferencia) {
            valid = false;
          }
        } else {
          if (item.valorneto === 0 || !item.valorneto) {
            valid = false;
          }
        }
      });

      if (!valid) errorMessages.push("Tienes campos pendientes por completar.");

      //Valores anticipo
      let anticipovalido = checkTotalDocument(
        valorapagar,
        opdocumentosreferencia
      );
      if (!anticipovalido)
        errorMessages.push(
          "El valor del anticipo es superior al valor de la factura. Por favor verifique la información."
        );

      //Valores cargos
      if (valorbase < valorcargosaplicados) {
        errorMessages.push(
          "El valor de los cargos no puede ser superior a la factura con descuentos"
        );
      }

      if (!motivonota)
        errorMessages.push("Debes seleccionar el motivo de la edición.");

      if (observacionesnota.trim().length === 0)
        errorMessages.push("Debes digitar la observación de la nota.");
    }

    if (errorMessages.length > 0) {
      let message = errorMessages.join("       ");
      WarningAlert("Upss...!", message);
      return false;
    }

    return true;
  };

  const readResponseSaveNote = (response) => {
    setLoading(false);
    if (response.data.statusCode === "201") {
      setModalResponse({
        type: "success",
        title: "Buen trabajo!!!",
        subtitle: "Has generado una nueva nota",
        body: (
          <div>
            <div></div>
            <div class="fenomenalWhiteLittle">
              Actualizaste la factura{" "}
              <span className="fenomenalBlueLittle">No. {documentoref}</span>{" "}
              con la Nota No.{" "}
              <span className="fenomenalBlueLittle">
                {response.data.result}
              </span>{" "}
              <span>de manera exitosa, sigamos facturando.</span> <br></br>{" "}
              {response.data.statusMessage}
            </div>
          </div>
        ),
        modalImage: "quotation",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div></div>
            <div class="fenomenalWhiteLittle">
              Actualizaste la factura{" "}
              <span className="fenomenalBlueLittle">No. {documentoref}</span>{" "}
              con la Nota No.{" "}
              <span className="fenomenalBlueLittle">
                {response.data.result}
              </span>{" "}
              {response.data.statusMessage}
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    }
  };

  const readErrorSaveNote = (response) => {
    setLoading(false);
    const statusCode = response.data.statusCode;
    const statusMessage = response.data.statusMessage;
    if (statusCode === "404" && statusMessage === "Unauthorized, no quota") {
      setModalResponse({
        type: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    } else {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: (
          <div>
            <div>
              {JSON.stringify(
                response === undefined
                  ? "Error inesperado"
                  : response.data.statusMessage
              )}
            </div>
          </div>
        ),
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 1,
        closeFromModalBody: true,
      });
    }
  };

  /**
   * Cierra modal mensaje envio documento
   * @param {*} rta
   */
  const handleResponseModal = (rta) => {
    setOpenModalEdit(false);
    if (rta === 1) {
      HandleBack();
    }

    setModalResponse({
      type: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
      closeFromModalBody: false,
    });
  };

  /**
   * Evento click cerrar modal confirmación tipo nota
   */
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  /**
   * Evento click cerrar modal anular factura
   */
  const handleCloseModalAnnulment = () => {
    setOpenModalAnnulment(false);
  };

  /**
   * Evento click abrir modal confirmación tipo nota
   */
  const handleOpenModalAnnulment = () => {
    setOpenModalAnnulment(true);
  };

  /**
   * Confirmar tipo nota a generar
   * @param {*} type Tipo nota
   */
  const confirmEditInvoice = (type) => {
    if (type === "ANU") {
      setOpenModalEdit(false);
      setOpenModalAnnulment(true);
    } else {
      dispatch(setTypeNoteAction(type));
      setOpenModalEdit(false);

      if (type !== tiponotaactiva) {
        dispatch(addReasonNoteAction(null));
      }
    }
  };

  /**
   * Confirmar anulación factura
   */
  const confirmAnnulmentInvoice = (observations) => {
    setLoadingNoteAnnulment(true);
    const note = generateRequestNote(documentdata, totals, observations, true);
    dispatch(
      createNoteAnnulmentAction(
        note,
        confirmSendOkAnnulment,
        confirmErrorAnnulment,
        null
      )
    );
  };

  /**
   * Lee response exitoso anulación factura
   * @param {*} response
   */
  const confirmSendOkAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response.data.statusCode === "201")
      successToast(
        "",
        `La factura ${invoiceBD.numero} fue anulada de manera exitosa, sigamos facturando`,
        "top-center"
      );
    else
      warningToast(
        "Ocurrió algo inesperado",
        `La factura ${invoiceBD.numero} fue anulada de manera exitosa, ${response.data.result}. ${response.data.statusMessage}`
      );

    cleanView();
  };

  /**
   * Lee response con error anulación factura
   * @param {*} response
   */
  const confirmErrorAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response?.data?.statusMessage !== "Unauthorized, no quota") {
      errorToast(
        "Ocurrió algo inesperado",
        `${JSON.stringify(
          response?.data?.statusMessage ??
            "Error inesperado, no se ha podido anular factura"
        )}`
      );
    }
    cleanView();
  };

  const cleanView = () => {
    HandleBack();
  };

  const footPage = (step) => {
    return (
      <div className="bottomButtonsInvoiceRevision">
        <Grid container spacing={3}>
          <Grid item lg={3} sm={3} xs={3} style={{ textAlign: "left" }}>
            <IconButton
              classes={{ root: classes.button, label: classes.labelHorizontal }}
              variant="raised"
              disableRipple={true}
              onClick={() => handleBackStep(step)}
              style={{ marginLeft: 10, marginTop: 10 }}
            >
              <ArrowBackIosIcon />
              <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
            </IconButton>
          </Grid>
          <Grid item lg={3} sm={3} xs={3}>
            <IconButton
              classes={{ root: classes.button, label: classes.label }}
              variant="raised"
              disableRipple={true}
              onClick={handleStandOut}
            >
              {favorito ? <StarIcon /> : <StarBorderIcon />}
              <span style={{ marginTop: 5 }}>Favorito</span>
            </IconButton>
          </Grid>
          <Grid item lg={3} sm={3} xs={3}>
            <IconButton
              classes={{ root: classes.button, label: classes.label }}
              variant="raised"
              disableRipple={true}
              onClick={handlePreviewDocument}
            >
              <VisibilityIcon />
              <span style={{ marginTop: 5 }}>Previsualizar</span>
            </IconButton>
          </Grid>
          <Grid item lg={3} sm={3} xs={3}>
            <IconButton
              classes={{ root: classes.button, label: classes.label }}
              variant="raised"
              disableRipple={true}
              onClick={handleCancelDocument}
              style={{ marginRight: 10 }}
            >
              <ClearIcon />
              <span style={{ marginTop: 5 }}>Cancelar</span>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <div></div>;
      case 1:
        return (
          <>
            <DocumentCartManage typedocument={"FA_NOTE"} />
            {!showCatalog && (
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xl={11} lg={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={handleOpenModalAnnulment}
                        text={"Anular Factura"}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={() => handleNextStep(2)}
                        text={
                          tiponotaactiva === "NC" || tiponotaactiva === "ANU"
                            ? "Revisar Nota Crédito"
                            : "Revisar Nota Débito"
                        }
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type="button"
                        onClick={handleSaveNote}
                        text={
                          tiponotaactiva === "NC" || tiponotaactiva === "ANU"
                            ? "Enviar Nota Crédito"
                            : "Enviar Nota Débito"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {footPage(1)}
          </>
        );
      case 2:
        return (
          <>
            <DocumentReview />
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xl={12} lg={12} sm={12} xs={12}>
                <Grid container spacing={1} justify="center">
                  <Grid item lg={4} xs={12}>
                    <ButtonPrimary
                      type="button"
                      onClick={handleSaveNote}
                      text={
                        tiponotaactiva === "NC" || tiponotaactiva === "ANU"
                          ? "Enviar Nota Crédito"
                          : "Enviar Nota Débito"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {footPage(2)}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className="container-form" xs={12} lg={12}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.root}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ""}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />

      <SimpleModal
        onClose={handleCloseModalEdit}
        open={openModalEdit}
        title={""}
        width={800}
        noPadding={true}
        component={() => (
          <InvoiceTypeNote
            onClick={confirmEditInvoice}
            loadedType={tiponotaactiva}
          />
        )}
      />

      <SimpleModal
        onClose={handleCloseModalAnnulment}
        open={openModalAnnulment}
        title={""}
        width={800}
        noPadding={true}
        disabled={loadingNoteAnnulment}
        disableBackdropClick={loadingNoteAnnulment}
        component={() => (
          <InvoiceAnnulmentNote
            onClick={confirmAnnulmentInvoice}
            number={documentoref ?? ""}
            loading={loadingNoteAnnulment}
          />
        )}
      />
      <ResponseRedirectModal
        modalType={"success"}
        title={"Upss...!!!"}
        subtitle={
          "Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites"
        }
        body={""}
        modalImage={"package"}
        open={modal}
        closeElement={"Skip"}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />

      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        handleClose={handleCloseFileModal}
      />
    </div>
  );
};
