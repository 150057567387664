import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';

import { createEquivalentDocAction } from '../../../../actions/equivalentDocAction';

import ButtonPrimary from '../../../../components/button/buttonPrimary.component';

import { MESSAGE_NEXT_CONFIRM } from '../../../../config/config';

import { ConfirmAlert } from '../../../../helpers/alert.helpers';
import { validateDocument } from '../../../../helpers/documentValidate';

import { generateRequestEquivalentDoc } from '../../utils';

export const DocumentCartButtons = ({ typedocument }) => {
  const dispatch = useDispatch();

  const totals = useSelector((state) => state.documentCartReducer);

  const { equivalentDocumentForm, customerEquivalentDoc } = useSelector(
    (state) => state.equivalentDocReducer
  );

  const textModal =
    typedocument === 'DE_NOTE'
      ? 'Al editar el documento equivalente se generara una nota de ajuste ¿Esta seguro que desea hacerlo?'
      : '¿Estás seguro de guardar y enviar el documento?';

  const handleSaveDocument = (e) => {
    e.preventDefault();
    if (validateDocument(totals)) {
      const deleteData = (confirmed) => {
        if (confirmed) {
          let document = generateRequestEquivalentDoc({
            equivalentDoc: equivalentDocumentForm,
            totalsCart: totals,
            customerDoc: customerEquivalentDoc,
          });

          dispatch(createEquivalentDocAction(document));
        }
      };
      ConfirmAlert(textModal, deleteData, MESSAGE_NEXT_CONFIRM);
    }
  };

  return (
    <Grid container style={{ marginTop: 11 }}>
      <Grid item xl={11} lg={12} sm={12} xs={12}>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item lg={6} xs={12}>
            {/* <ButtonPrimary
              type="button"
              onClick={() => handleChangeStep(2)}
              text={"Revisar Documento"} //! Pendiente de implementar
            /> */}
          </Grid>
          <Grid item lg={6} xs={12}>
            <ButtonPrimary
              type='button'
              onClick={handleSaveDocument}
              text={'Enviar Documento'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
