import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from "../config/config";
import { axiosApiInstance } from "../config/axios-instance";
import { enterpriseIdHeader, authHeader } from "./authActions";
import { WarningAlert } from "../helpers/alert.helpers";
import { types } from "../types/types";
import { warningToast } from "../helpers/toast.helpers";

const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

let branchOffices = [];

/**
 *  Consula información de empresa asociada al usuario logueado.
 *  ajustes
 */
export const getCompanyAction = (checkData) => async (dispatch, getState) => {
  try {
    const { requestCompany } = getState().basicDataReducer;
    if (!!requestCompany && checkData === true) {
      return;
    }

    changeGetLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    const response = await axiosApiInstance.get(
      `${urlEnterprise}/GetEnterprise`,
      config
    );
    dispatch({
      type: types.GET_COMPANY,
      payload: response.data.result,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido obtener información de la empresa.",
        error: err,
      },
    });
  } finally {
    changeGetLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta información de empresa
 * @param {*} dispatch
 * @param {boolean} status Estado loading
 */
function changeGetLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_GET_BASIC_DATA,
    payload: status,
  });
}

export const getInfoIterprice = () => {
  const config = {
    headers: {
      Authorization: `${authHeader()}`,
      pEnterpriseId: `${enterpriseIdHeader()}`,
    },
  };
  return axiosApiInstance
    .get(`${urlEnterprise}/GetEnterprise`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Actualiza información basica de empresa
 */
export const updateBasicDataAction = (body) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.put(`${urlEnterprise}`, body, config);

    if (resp?.data?.statusCode === "251") {
      warningToast("Transacción incompleta", resp?.data?.statusMessage);
      return;
    }

    dispatch({
      type: types.SAVE_BASIC_DATA,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar la información de la empresa.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading Registro a actualización
 * información basica
 * @param {*} dispatch
 * @param {*} status
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_BASIC_DATA,
    payload: status,
  });
}

export const filterByOffice = (sucursalid, filter) => {
  let office = branchOffices.find((office) => office.id === sucursalid);
  return !!office ? office.nombre.toLowerCase().indexOf(filter) : -1;
};

/**
 * Verifica si la empresa del usuario logueado tiene un certificado digital valido
 */
export const checkCertificateCompanyAction = (
  checkData,
  loadingCreateInvoice
) => async (dispatch) => {
  try {
    loadingCreateInvoice(true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.get(
      `${urlEnterprise}/GetCertificate`,
      config
    );
    checkData(response.data.result);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido verificar certificado digital de la empresa.",
        error: err,
      },
    });
  } finally {
    loadingCreateInvoice(false);
  }
};

/**
 * Verifica si la empresa del usuario logueado puede cargar masivamente
 */
export const checkBulkUploadCompanyAction = (
  checkData,
  loadingOpenBulkUpload
) => async (dispatch) => {
  try {
    loadingOpenBulkUpload(true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    var response = { requiere: true, activo: true };
    checkData(response);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido verificar cargue masivo de la empresa.",
        error: err,
      },
    });
  } finally {
    loadingOpenBulkUpload(false);
  }
};

/**
 * Verifica si la empresa del usuario logueado tiene un certificado digital valido
 */
export const checkCertificateToEditInvoiceAction = (
  checkData,
  loadingEditInvoice,
  invoiceData
) => async (dispatch) => {
  try {
    loadingEditInvoice(true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.get(
      `${urlEnterprise}/GetCertificate`,
      config
    );
    checkData(response.data.result, invoiceData);
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido verificar certificado digital de la empresa.",
        error: err,
      },
    });
  } finally {
    loadingEditInvoice(false);
  }
};

/**
 * Actualiza informacion empresa
 */
export const updateInitialData = (body, completeSave) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateInitialData`,
      body,
      config
    );
    completeSave();
    dispatch({
      type: types.UPDATE_INITIAL_DATA,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar la información de la empresa.",
        error: err,
      },
    });
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza data Dian
 */
export const updateDianData = (data, completeSave) => async (dispatch) => {
  try {
    changeUpdateDianDataLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    //Ajustar data
    data = {
      ...data,
      EmpresaId: enterpriseIdHeader(),
    };

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateDianData`,
      data,
      config
    );
    completeSave(response.data);
    dispatch({
      type: types.UPDATE_DIAN_DATA,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido actualizar la información de la empresa.",
        error: err,
      },
    });
  } finally {
    changeUpdateDianDataLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading actualización
 * información DIAN
 * @param {*} dispatch
 * @param {*} status
 */
function changeUpdateDianDataLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_DIAN_DATA,
    payload: status,
  });
}

/**
 * Agrega nuevo dtao adicional de cliente para facturación
 * @param {*} data Información nuevo dato
 */
export const saveCustomFieldAction = (
  data,
  listNewData,
  completeSaveData
) => async (dispatch) => {
  try {
    changeSaveCustomFieldLoading(dispatch, true);

    if (data.guardar === true) {
      let body = {
        Codigo: data.codigo,
        Valor: data.valor,
        EmpresaId: enterpriseIdHeader(),
      };

      var res = await axiosApiInstance.post(
        `${urlEnterprise}/SaveAdditionalData`,
        body,
        defaultHeaders()
      );

      //Campo existente en Bd
      if (res.data.statusCode === "210") {
        WarningAlert(
          "Campo existente",
          `Ya agregaste un campo con el nombre ${data.codigo}`
        );
        return;
      }

      data = {
        ...data,
        id: res.data.result,
      };
    }

    dispatch({
      type: types.SAVE_CUSTOM_FIELD,
      payload: {
        status: 200,
      },
    });

    listNewData = listNewData.concat(data);
    dispatch({
      type: types.LIST_CUSTOM_FIELDS,
      payload: listNewData,
    });

    completeSaveData();
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido agregar nuevo dato adicional.",
        error: err,
      },
    });
  } finally {
    changeSaveCustomFieldLoading(dispatch, false);
  }
};

/**
 * Agrega nuevo dtao adicional de cliente para facturación
 * @param {*} data Información nuevo dato
 */
export const deleteCustomFieldAction = (data, list) => async (dispatch) => {
  try {
    changeDeleteCustomFieldLoading(dispatch, data.id);
    let listNewData = [];
    if (data.guardar === true) {
      let config = defaultHeaders();
      Object.assign(config.headers, {
        pId: data.id,
      });

      var res = await axiosApiInstance.post(
        `${urlEnterprise}/DeleteAdditionalData`,
        null,
        config
      );
      if (res.status === 201) {
        listNewData = list.filter((c) => c.id !== data.id);
      }
    } else {
      listNewData = list.filter((c) => c.id !== data.id);
    }

    dispatch({
      type: types.LIST_CUSTOM_FIELDS,
      payload: listNewData,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido eliminar  dato adicional.",
        error: err,
      },
    });
  } finally {
    changeDeleteCustomFieldLoading(dispatch, 0);
  }
};

/**
 * Actualiza estado loading actualización
 * información DIAN
 * @param {*} dispatch
 * @param {*} status
 */
function changeSaveCustomFieldLoading(dispatch, status) {
  dispatch({
    type: types.LOADING_SAVE_CUSTOM_FIELD,
    payload: status,
  });
}

/**
 * Actualiza estado loading borrado
 * información DIAN
 * @param {*} dispatch
 * @param {*} id
 */
function changeDeleteCustomFieldLoading(dispatch, id) {
  dispatch({
    type: types.LOADING_DELETE_CUSTOM_FIELD,
    payload: id,
  });
}

/**
 * Actualiza listado nuevos datos cliente
 * @param {*} data Información nuevo dato
 */
export const updateListCustomFieldsAction = (listNewData) => async (
  dispatch
) => {
  dispatch({
    type: types.LIST_CUSTOM_FIELDS,
    payload: listNewData,
  });
};

/**
 * Obtiene listado de datos adicionales cliente
 */
export const getAdditionalDataAction = (
  listDocumentCustomFields = []
) => async (dispatch, getState) => {
  try {
    // const { listCustomFields } = getState().basicDataReducer;

    changeGetCustomFieldLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: enterpriseIdHeader(),
    });

    var res = await axiosApiInstance.get(
      `${urlEnterprise}/GetAdditionalData`,
      config
    );

    let newList = res.data.result.map((item) => {
      return {
        ...item,
        guardar: true,
      };
    });

    if (
      listDocumentCustomFields !== null &&
      listDocumentCustomFields !== undefined &&
      listDocumentCustomFields.length > 0
    ) {
      listDocumentCustomFields = listDocumentCustomFields.map((item) => {
        return {
          ...item,
          setvalue: true,
        };
      });

      newList = newList.concat(listDocumentCustomFields);
      newList = newList.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.codigo.toLowerCase().replaceAll(" ", "") ===
              item.codigo.toLowerCase().replaceAll(" ", "")
          )
      );
    }

    dispatch({
      type: types.LIST_CUSTOM_FIELDS,
      payload: newList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado de campos adicionales.",
        error: err,
      },
    });
  } finally {
    changeGetCustomFieldLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading borrado
 * información DIAN
 * @param {*} dispatch
 * @param {*} id
 */
function changeGetCustomFieldLoading(dispatch, id) {
  dispatch({
    type: types.LOADING_GET_CUSTOM_FIELD,
    payload: id,
  });
}

/**
 * Limpia información de redux, listado campos adicionales cliente
 */
export const clearListAdditionalDataAction = () => async (dispatch) => {
  dispatch({
    type: types.LIST_CUSTOM_FIELDS,
    payload: [],
  });
};
