import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputSearch from '../../../components/input/inputSearch.component';
import CustomProgress from '../../../components/Progress/progress.component';

import { getReasonNotes } from '../../../actions/invoiceActions';
import { getConceptsNotesAdjustment } from '../../../actions/documentSupportAction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
    backgroundColor: theme.palette.primaryBackgroundColor,
    color: theme.palette.primaryColor,
    border: 'none',
    minHeight: 130,
    overflowX: 'hidden',
  },
  listStyle: {
    backgroundColor: theme.palette.primaryBackgroundColor,
    color: theme.palette.primaryColor,
  },
  item: {
    fontSize: 12,
    marginBottom: 3,
    '&.MuiListItem-root:hover': {
      backgroundColor: 'rgba(255,255,255,0.16)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(255,255,255,0.16)',
    },
    '& .MuiTypography-displayBlock': {
      fontSize: 14,
    },
  },
  itemButton: {
    fontSize: 12,
    marginBottom: 3,
    '&.Mui-selected': {
      backgroundColor: 'rgba(255,255,255,0.16)',
    },
    '& .MuiTypography-displayBlock': {
      fontSize: 14,
    },
  },
  icon: {
    color: 'gray',
  },
  noResult: {
    marginBottom: 5,
    padding: 5,
    paddingLeft: 15,
  },
  containerInput: {
    padding: '10px 15px',
  },
  padding: {
    padding: 10,
  },
}));

export const ReasonsList = React.memo(({ handleSelection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [reasonsList, setReasonsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState('');

  let { reasons, loadingGetReasons } = useSelector(
    (state) => state.invoiceReducer
  );
  const { conceptsNotesAdjustment } = useSelector(
    (state) => state.documentSupportReducer
  );
  const { tiponotaactiva } = useSelector((state) => state.documentReducer);
  const { cartDocumentSupport } = useSelector(
    (state) => state.documentCartReducer
  );

  /**
   * Cargue inicial
   */
  useEffect(() => {
    dispatch(getReasonNotes());
    dispatch(getConceptsNotesAdjustment());
  }, [dispatch]);

  /**
   * Actualiza listado a mostrar en pantalla
   */
  useEffect(() => {
    if (!cartDocumentSupport) {
      setReasonsList(reasons.filter((c) => c.tipo === tiponotaactiva));
    } else {
      setReasonsList(conceptsNotesAdjustment);
    }
  }, [reasons, tiponotaactiva, conceptsNotesAdjustment, cartDocumentSupport]);

  /**
   * Sincroniza nuevo valor input con state
   * @param {*} param0
   */
  const handleChangeFilter = ({ target }) => {
    setFilter(target.value);
    let newList = filterList(target.value);
    setReasonsList(newList);
  };

  /**
   * Marca como seleccionado item de lista
   * @param {*} index
   */
  const handleItemClick = (item) => {
    setSelectedIndex(item.id);
    handleSelection(item);
  };

  /**
   * Filtra listado por nombre
   * @param {*} value
   * @returns
   */
  const filterList = (value) => {
    if (value !== '') {
      if (!cartDocumentSupport) {
        let newList = reasons.filter((c) =>
          c.descripcion
            .toLowerCase()
            .match(new RegExp(value.toLowerCase() + '.*'))
        );
        return newList;
      } else {
        let newList = conceptsNotesAdjustment.filter((c) =>
          c.descripcion
            .toLowerCase()
            .match(new RegExp(value.toLowerCase() + '.*'))
        );
        return newList;
      }
    }
    return !cartDocumentSupport ? reasons : conceptsNotesAdjustment;
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerInput}>
        <InputSearch
          label={'Buscar motivo...'}
          maxLength={15}
          value={filter}
          onChange={handleChangeFilter}
        />
      </div>
      {loadingGetReasons === true ? (
        <CustomProgress />
      ) : (
        <List className={classes.listStyle} component='nav'>
          {reasonsList.length ? (
            <>
              {reasonsList.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.item}
                    button
                    selected={selectedIndex === item.id}
                    onClick={() => handleItemClick(item)}
                  >
                    <ListItemText primary={item.descripcion} />
                  </ListItem>
                );
              })}
            </>
          ) : (
            <div className={classes.noResult}>
              No se han encontrado resultados...
            </div>
          )}
        </List>
      )}
    </div>
  );
});
