import React from "react";

import { makeStyles } from "@material-ui/core";

import {
  AdditionalDocInfoComponent,
  BoxAdditionalInformation,
  DocInformationData,
  DocumentArticles,
} from "./";

import CollapsePanel from "../../../../../components/collapse/collapsestyles.component";

import { FooterDetail } from "../../footerActions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70.9vh",
    overflow: "auto",
    padding: "3%",
  },
  footerRoot: {
    height: "7vh",
    width: "99.5%",
    [theme.breakpoints.down("lg")]: {
      height: "9vh",
    },
  },
  spacing: {
    marginBottom: "20px",
  },
}));

/**
 * * Datos de los paneles a mostrar en una interfaz.
 * @property {React.ComponentType} component - El componente a renderizar en el panel.
 * @property {string} title - El título del panel cuando está cerrado.
 * @property {string} titleOpen - El título del panel cuando está abierto.
 * @property {string} type - El tipo de panel.
 */
const panelsData = [
  {
    component: AdditionalDocInfoComponent,
    title: "Información adicional de pago",
    titleOpen: "Información adicional de pago",
    type: "showinfo",
  },
  {
    component: BoxAdditionalInformation,
    title: "Información adicional de caja",
    titleOpen: "Información adicional de caja",
    type: "showinfo",
  },
];

/**
 * * Configuración de los fondos para los detalles.
 * @property {string} backgroundColorDetail.background - El color de fondo para el detalle.
 * @property {string} backgroundColorSummary.backgroundColor - El color de fondo para el resumen.
 * @property {string} backgroundColorSummary.color - El color del texto para el resumen.
 */
const detailBackground = {
  backgroundColorDetail: {
    background: "#5E5E5E",
  },
  backgroundColorSummary: {
    backgroundColor: "#2CC63E",
    color: "#000000",
  },
};

export const EquivalentDocumentInformationSection = ({
  equivalentDocument,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={`cardDetailSupportFullData ${classes.root} animate__animated animate__fadeIn animate__faster`}
      >
        <DocInformationData />
        <div className={classes.spacing} />
        <DocumentArticles {...equivalentDocument} />
        {panelsData.map((panel, index) => (
          <div key={panel.title}>
            <CollapsePanel {...panel} {...detailBackground} />
            <div className={classes.spacing} />
          </div>
        ))}
      </div>
      {/* Fin Seccion 2 */}
      <div className={classes.footerRoot}>
        <FooterDetail />
      </div>
      {/* Fin Seccion 3 */}
    </>
  );
};
