import React from "react";

import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { ValueInfoCard } from "./";

export const AdditionalDocInfoComponent = () => {
  const {
    acuseDIANDate,
    cude,
    dateDocEquivalent,
    observationText,
    paymentDate,
    paymentMethodDescription,
    paymentMethodDetailDescription,
  } = useSelector(
    (state) => state.equivalentDocReducer.additionalDocInfo,
    (prev, next) => prev === next
  );

  /**
   * * Datos adicionales para mostrar en una sección de información adicional.
   * @property {string} title - El título del dato.
   * @property {string} value - El valor del dato.
   * @property {boolean} [show] - Indica si el dato debe mostrarse (opcional).
   * @property {number} [md] - El tamaño del grid en dispositivos de tamaño medio (md) (opcional).
   * @property {number} [lg] - El tamaño del grid en dispositivos de tamaño grande (lg) (opcional).
   */
  const additionalInfoData = [
    {
      title: "CUDE",
      value: cude,
      show: !!cude,
    },
    {
      title: "Fecha de documento",
      value: dateDocEquivalent,
      md: 4,
      lg: 4,
    },
    {
      title: "Forma de pago",
      value: paymentMethodDescription,
      md: 4,
      lg: 4,
    },
    {
      title: "Medio de pago",
      value: paymentMethodDetailDescription,
      md: 4,
      lg: 4,
    },
    {
      title: "Fecha de pago",
      value: paymentDate,
      md: 6,
      lg: 6,
    },
    {
      title: "Fecha validación DIAN",
      value: acuseDIANDate,
      md: 6,
      lg: 6,
    },
    {
      title: "Observaciones",
      value: observationText,
    },
  ];

  return (
    <div>
      <Grid container>
        {additionalInfoData.map(
          (infoData, index) =>
            infoData.show !== false && (
              <ValueInfoCard key={index} {...infoData} />
            )
        )}
      </Grid>
    </div>
  );
};
